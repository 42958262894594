import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import 'antd/dist/antd.less';
import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EuroCircleOutlined,
  FieldBinaryOutlined,
  MailOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  differenceInDays, format, isValid,
} from 'date-fns';
import { es } from 'date-fns/locale';
import Colors from '../../../assets/colors/Colors';
import ConstantsPayStates from '../../../constants/ConstantsPayStates';
import {
  actionAddPayReservation,
  actionPayChargeReservation,
  actionPayChargeReservationResult,
  actionRemoveNewPayReservation,
  actionRemovePayReservation,
} from '../../../store/reducers/payments/paymentsActions';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';
import ConstantsLanguages from '../../../constants/ConstantsLanguages';
import ConstantsDate from '../../../constants/ConstantsDate';
import {
  getCurrencyCode, getWritePermissionByTab,
  getIsAdminPermission, canCollectVirtualCardPermission
} from '../../../utils/Utils';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';

import TpvManualModal from './TpvManualModal';

import {
  useCreateInvitationApiMutation
} from '../../../services/invitations';

const { Text } = Typography;
const { Option } = Select;

const styles = {
  div: {
    width: '100%',
    backgroundColor: Colors.BACKGROUND_COLOR_3,
    paddingLeft: 46,
    paddingTop: 20,
    paddingRight: 46,
    paddingBottom: 20,
  },
  row: {
    marginBottom: 40,
  },
  col: { marginRight: 100 },
  textTitle1: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    marginRight: 4,
  },
  textTitle2: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  textPay: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    fontWeight: '500',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bluePoint: {
    width: 10, height: 10, backgroundColor: Colors.PRIMARY_COLOR, borderRadius: 5,
  },
  blueLine: {
    width: '100%', height: 2, backgroundColor: Colors.PRIMARY_COLOR,
  },
  greyPoint: {
    width: 10, height: 10, backgroundColor: Colors.PROGRESS_GREY_LINE, borderRadius: 5,
  },
  greyLine: {
    width: '100%', height: 2, backgroundColor: Colors.PROGRESS_GREY_LINE,
  },
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_YELLOW,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
  iconSave: {
    color: Colors.TEXT_GREEN,
  },
  iconEdit: {
    color: Colors.PRIMARY_COLOR,
  },
  iconDelete: {
    color: Colors.TEXT_RED,
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    marginTop: 34,
  },
  select: {
    width: 100,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  select2: {
    width: 160,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    paddingTop: 6,
  },
  textTitlePopconfirm: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  textOkButtonPopconfirm: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    color: 'white',
  },
  textCancelButtonPopconfirm: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  inputModal: {
    width: '100%',
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  selectModal: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  buttonPrimaryModal: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
  },
};

export default function ReservationPays(props) {
  const [payType, setPayType] = useState('');
  const [actionPay, setActionPay] = useState('');
  const [transaction, setTransaction] = useState('');
  const [statePay, setStatePay] = useState('');
  const [date, setDate] = useState('');
  const [amount, setAmount] = useState('');
  const [visible, setVisible] = useState(false);
  const [nameLastname, setNameLastname] = useState('');
  const [emailPay, setEmailPay] = useState('');
  const [serviceAmount, setServiceAmount] = useState('0');
  const [currency, setCurrency] = useState('');
  const [language, setLanguage] = useState(ConstantsLanguages.ES);

  const [visibleTpvManual, setVisibleTpvManual] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.reducerFilters);
  const addPayReservationResult = useSelector((state) => state.reducerAddPayReservationResult);
  const removePayReservationResult = useSelector(
    (state) => state.reducerRemovePayReservationResult
  );
  const payChargeReservationResult = useSelector(
    (state) => state.reducerPayChargeReservationResult
  );
  const loading = useSelector((state) => state.reducerLoading);

  const [
    createInvitation,
    {
      data: createInvitationResult,
      isLoading: isLoadingCreateInvitation, // TODO annadir spinner
      error: errorCreateInvitation
    }
  ] = useCreateInvitationApiMutation();

  const { reservation } = props;

  const canCollectVirtualCards = canCollectVirtualCardPermission() || getIsAdminPermission();

  useEffect(() => {
    setLanguage(ConstantsLanguages.ES);
  }, []);

  if (errorCreateInvitation) {
    message.error(errorCreateInvitation.message);
  }
  if (createInvitationResult?.message) {
    message.success(createInvitationResult.message);
  }

  useEffect(() => {
    if (addPayReservationResult.msg !== null && addPayReservationResult.result === false) {
      message.error(addPayReservationResult.msg);
    }
    if (addPayReservationResult.result === true) {
      message.success(addPayReservationResult.msg);
    }

    if (removePayReservationResult.msg !== null && removePayReservationResult.result === false) {
      message.error(removePayReservationResult.msg);
    }
    if (removePayReservationResult.result === true) {
      message.success(removePayReservationResult.msg);
    }
    if (payChargeReservationResult.msg !== null && payChargeReservationResult.result === false) {
      message.error(payChargeReservationResult.msg);
      dispatch(actionPayChargeReservationResult(false, null));
    }
    if (payChargeReservationResult.result === true) {
      message.success(payChargeReservationResult.msg);
      dispatch(actionPayChargeReservationResult(false, null));
    }

    if (reservation !== null && nameLastname === '') {
      setNameLastname(reservation.usuario ?? '');
    }
    if (reservation !== null && emailPay === '') {
      setEmailPay(reservation.email ?? '');
    }
    if (reservation !== null && serviceAmount === '0') {
      setServiceAmount(reservation.pendiente_pago ?? '0');
    }
    if (reservation !== null && currency === '') {
      setCurrency(reservation.currency ?? '');
    }
  });

  const handleOk = () => {
    setVisible(false);
    createInvitation({
      nameLastname,
      email: emailPay,
      currency,
      concept: 'pago reserva de referencia',
      language,
      amount: serviceAmount,
      hotelCode: reservation.hotel,
      dateInitSend: '',
      dateEndSend: '',
      localizator: reservation.reservation_id,
    });
  };

  const handleCancel = () => {
    setNameLastname('');
    setEmailPay('');
    setServiceAmount('0');
    setCurrency('');
    setVisible(false);
  };

  function clickSavePay(record) {
    if (payType.length === 0
      || actionPay.length === 0
      || transaction.length === 0
      || statePay.length === 0
      || date.length === 0
      || amount.length === 0) {
      message.error(t('error_empty_fields'));
    } else {
      dispatch(actionLoading(true));
      dispatch(actionAddPayReservation(
        payType,
        actionPay,
        transaction,
        statePay,
        format(date.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        }),
        amount,
        reservation.reservation_id,
        record.id_cobro,
      ));

      setPayType('');
      setActionPay('');
      setTransaction('');
      setStatePay('');
      setDate('');
      setAmount('');
    }
  }

  function clickRemovePay(record) {
    dispatch(actionLoading(true));
    dispatch(actionRemovePayReservation(reservation.reservation_id, record.id_cobro));
  }

  const actionsOptions = [];
  filters.payActions.forEach((i) => {
    actionsOptions.push(
      <Option key={i.action} value={i.action}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const typesPayOptions = [];
  filters.payTypes?.forEach((i) => {
    typesPayOptions.push(
      <Option key={i.tipo} value={i.tipo}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const statesPayOptions = [];
  filters.payStates.forEach((i) => {
    statesPayOptions.push(
      <Option key={i.estado} value={i.estado}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const hotelsOptions = [];
  filters.hotels.forEach((i) => {
    hotelsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const languageOptions = [];
  languageOptions.push(
    <Option key={ConstantsLanguages.ES} value={ConstantsLanguages.ES}>
      <Text style={styles.textOptionValue}>
        {t('text_language_es')}
      </Text>
    </Option>,
  );
  languageOptions.push(
    <Option key={ConstantsLanguages.EN} value={ConstantsLanguages.EN}>
      <Text style={styles.textOptionValue}>
        {t('text_language_en')}
      </Text>
    </Option>,
  );

  const currencyOptions = [];
  filters.currencies.forEach((i) => {
    currencyOptions.push(
      <Option value={i.currency}>
        <Text style={styles.textOptionValue}>
          {i.currency}
        </Text>
      </Option>,
    );
  });
  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_action')}
        </Text>),
      dataIndex: 'action',
      key: 'action',
      responsive: ['md'],
      render: (text, record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: record.create || record.edit ? (
          <Select
            style={styles.select}
            placeholder={t('text_action')}
            optionFilterProp="children"
            onChange={(value) => {
              setActionPay(value);
            }}
            value={actionPay}
          >
            {actionsOptions}
          </Select>
        ) : (
          <Text className="text">
            {text}
          </Text>
        ),
      }),
    },
    {
      title: (
        <Text className="text">
          {t('text_transaction')}
        </Text>),
      dataIndex: 'transaccion',
      key: 'transaccion',
      responsive: ['md'],
      render: (text, record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: record.create || record.edit ? (
          <Input
            placeholder={t('text_transaction')}
            onChange={(value) => {
              setTransaction(value.target.value);
            }}
            value={transaction}
            style={styles.input}
          />
        ) : (
          <Text className="text">
            {text}
          </Text>
        ),
      }),
    },
    {
      title: (
        <Text className="text">
          {t('text_ota_code')}
        </Text>),
      dataIndex: 'tipo',
      key: 'tipo',
      responsive: ['md'],
      render: (text, record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: record.create || record.edit ? (
          <Select
            style={styles.select2}
            dropdownStyle={{ minWidth: '40%' }}
            placeholder={t('text_ota_code')}
            optionFilterProp="children"
            onChange={(value) => {
              setPayType(value);
            }}
            value={payType}
          >
            {typesPayOptions}
          </Select>
        ) : (
          <Text className="text">
            {text}
          </Text>
        ),
      }),
    },
    {
      title: (
        <Text className="text">
          {t('text_date')}
        </Text>),
      dataIndex: 'fecha_transaccion',
      key: 'fecha_transaccion',
      responsive: ['md'],
      render: (text, record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: record.create || record.edit ? (
          <DatePicker
            style={styles.input}
            format={ConstantsDate.DATE_FORMAT_PICKER}
            onChange={(newDate) => {
              setDate(newDate);
            }}
          />
        ) : (
          <Text className="text">
            {text.replaceAll('-', '/')}
          </Text>
        ),
      }),
    },
    {
      title: (
        <Text className="text">
          {t('text_status')}
        </Text>),
      dataIndex: 'estado',
      key: 'estado',
      responsive: ['md'],
      render: (text, record) => {
        let styleText;
        if (text === ConstantsPayStates.Ok) {
          styleText = styles.textValueGreenColumn;
        } else if (text === ConstantsPayStates.Pending) {
          styleText = styles.textValueRedColumn;
        } else {
          styleText = styles.textValueYellowColumn;
        }
        return {
          props: {
            style: { background: Colors.BACKGROUND_COLOR_3 },
          },
          children: record.create || record.edit ? (
            <Select
              style={styles.select}
              dropdownStyle={{ minWidth: '20%' }}
              placeholder={t('text_status')}
              optionFilterProp="children"
              onChange={(value) => {
                setStatePay(value);
              }}
              value={statePay}
            >
              {statesPayOptions}
            </Select>
          ) : (
            <Text style={styleText}>
              {text}
            </Text>
          ),
        };
      },
    },
    {
      title: (
        <Text className="text">
          {t('text_pay_total')}
        </Text>),
      dataIndex: 'amount',
      key: 'amount',
      responsive: ['md'],
      render: (text, record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: record.create || record.edit ? (
          <Input
            value={amount}
            prefix={reservation.currency}
            placeholder={t('text_pay_total')}
            onChange={(value) => {
              setAmount(value.target.value);
            }}
            style={styles.input}
          />
        ) : (
          <Text className="text">
            {`${text} ${getCurrencyCode(reservation.currency)}`}
          </Text>
        ),
      }),
    },
    {
      title: '',
      responsive: ['md'],
      render: (record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: (
          <Row>
            <Col>
              {canCollectVirtualCards && isValid(new Date(record.fecha_transaccion)) && differenceInDays(new Date(record.fecha_transaccion), new Date()) <= 4
              && record.tipo === 'SD'
              && record.estado === ConstantsPayStates.Pending ? (
                <Tooltip title={t('text_button_charge')}>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => {
                      dispatch(actionLoading(true));
                      dispatch(actionPayChargeReservation(record.id_cobro));
                    }}
                    icon={<EuroCircleOutlined />}
                    style={styles.buttonLink}
                  />
                </Tooltip>
                ) : (
                  <div />
                )}
            </Col>
            <Col>
              {record.create ? (
                <Tooltip title={t('text_save_pay')}>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => {
                      clickSavePay(record);
                    }}
                    icon={<CheckOutlined style={styles.iconSave} />}
                    disabled={!record.create && !record.edit}
                    style={styles.buttonLink}
                  />
                </Tooltip>
              ) : (
                <div />
              )}
            </Col>
            <Col>
              {record.manual === '1' || record.create ? (
                <Popconfirm
                  placement="bottomRight"
                  title={(
                    <Text style={styles.textTitlePopconfirm}>
                      {t('text_question_delete_pay')}
                    </Text>
                  )}
                  icon={<QuestionCircleOutlined style={styles.icon} />}
                  onConfirm={() => {
                    if (record.create) {
                      dispatch(actionRemoveNewPayReservation(
                        reservation.reservation_id,
                        record.id_cobro
                      ));
                      setPayType('');
                      setActionPay('');
                      setTransaction('');
                      setStatePay('');
                      setDate('');
                      setAmount('');
                    } else {
                      clickRemovePay(record);
                    }
                  }}
                  okText={(
                    <Text style={styles.textOkButtonPopconfirm}>
                      {t('text_yes')}
                    </Text>
                  )}
                  cancelText={(
                    <Text style={styles.textCancelButtonPopconfirm}>
                      {t('text_no')}
                    </Text>
                  )}
                >
                  {getWritePermissionByTab(ConstantsRoutes.PAYMENTS) ? (
                    <Tooltip title={t('text_delete_pay')}>
                      <Button
                        type="link"
                        htmlType="button"
                        disabled={!getWritePermissionByTab(ConstantsRoutes.PAYMENTS)}
                        icon={<DeleteOutlined style={styles.iconDelete} />}
                        style={styles.buttonLink}
                      />
                    </Tooltip>
                  ) : <div />}
                </Popconfirm>
              ) : null}
            </Col>
          </Row>),
      }),
    },
  ];

  const paidOut = reservation.total_pagado === undefined ? 0 : Number(reservation.total_pagado);
  const paidIn = reservation.pendiente_pago === undefined ? 0 : Number(reservation.pendiente_pago);

  return (
    <div style={styles.div}>
      {visibleTpvManual ? (<TpvManualModal reservation={reservation} closeModal={() => setVisibleTpvManual(false)} />) : null }
      <Modal
        title={(
          <Text style={styles.textTitlePopconfirm}>
            {t('new_pay_by_link')}
          </Text>
        )}
        destroyOnClose
        open={visible}
        footer={null}
        onCancel={handleCancel}
        closeIcon={<CloseCircleOutlined style={styles.icon} />}
      >
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
            size: 'middle',
          }}
        >
          <Form.Item
            name="nameLastname"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined style={styles.icon} />}
              placeholder={t('hint_name_lastname')}
              defaultValue={reservation.usuario ?? ''}
              onChange={(text) => {
                setNameLastname(text.target.value);
              }}
              value={nameLastname}
              style={styles.inputModal}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
          >
            <Input
              prefix={<MailOutlined style={styles.icon} />}
              placeholder={t('hint_email')}
              defaultValue={reservation.email ?? ''}
              onChange={(text) => {
                setEmailPay(text.target.value);
              }}
              type="email"
              value={emailPay}
              style={styles.inputModal}
            />
          </Form.Item>
          <Form.Item
            name="hotelCode"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
          >
            <Select
              disabled
              defaultValue={reservation.hotel}
              style={styles.selectModal}
              placeholder={t('hint_hotel_name')}
              value={reservation.hotel}
            >
              {hotelsOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="confirmationReservationCode"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
          >
            <Input
              disabled
              prefix={<FieldBinaryOutlined style={styles.icon} />}
              placeholder={t('hint_confirmation_reservation_code')}
              defaultValue={reservation.reservation_id}
              value={reservation.reservation_id}
              style={styles.inputModal}
            />
          </Form.Item>
          <Form.Item
            name="language"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
          >
            <Select
              style={styles.selectModal}
              placeholder={t('text_language')}
              optionFilterProp="children"
              defaultValue={ConstantsLanguages.ES}
              onChange={(value) => {
                setLanguage(value);
              }}
              value={language}
            >
              {languageOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="serviceAmount"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
          >
            <Input
              prefix={<EuroCircleOutlined style={styles.icon} />}
              placeholder={t('hint_amount_service')}
              defaultValue={reservation.pendiente_pago ?? '0'}
              onChange={(text) => {
                setServiceAmount(text.target.value);
              }}
              value={serviceAmount}
              style={styles.inputModal}
            />
          </Form.Item>
          <Form.Item
            name="currency"
            rules={[
              {
                required: true,
                message: t('error_empty_field'),
              },
            ]}
            style={{ display: 'inline-block', width: 'calc(30% - 8px)', marginLeft: 8 }}
          >
            <Select
              style={styles.select}
              placeholder={t('text_currency')}
              defaultValue={reservation.currency ?? ''}
              optionFilterProp="children"
              onChange={(value) => {
                setCurrency(value);
              }}
              value={currency}
            >
              {currencyOptions}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={nameLastname.length === 0
                || emailPay.length === 0
                || serviceAmount.length === 0
                || serviceAmount === '0'
                || currency.length === 0}
              onClick={handleOk}
              style={styles.buttonPrimaryModal}
            >
              {t('text_button_generate_link')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row align="middle" style={styles.row}>
        <Col style={styles.col}>
          <Row>
            <Text style={styles.textTitle1}>
              {`${t('text_pay_total')}:`}
            </Text>
            <Text style={styles.textTitle2}>
              {`${reservation.gross} ${getCurrencyCode(reservation.currency)}`}
            </Text>
          </Row>
        </Col>
        <Col>
          <Row justify="center" align="bottom">
            <Text style={styles.textPay}>
              {`${t('text_pay_out')}: ${paidOut === 0 ? '0.00' : paidOut} ${getCurrencyCode(reservation.currency)}`}
            </Text>
          </Row>
          <Row justify="center" align="middle">
            <Col span={1}>
              <div style={paidOut === 0 ? styles.greyPoint : styles.bluePoint} />
            </Col>
            <Col span={22}>
              <div style={paidOut === 0 ? styles.greyLine : styles.blueLine} />
            </Col>
            <Col span={1}>
              <div style={paidOut === 0 ? styles.greyPoint : styles.bluePoint} />
            </Col>
          </Row>
        </Col>
        <Col style={{ marginLeft: -6 }}>
          <Row justify="center" align="bottom">
            <Text style={styles.textPay}>
              {`${t('text_pay_pending')}: ${paidIn === 0 ? '0.00' : paidIn} ${getCurrencyCode(reservation.currency)}`}
            </Text>
          </Row>
          <Row justify="center" align="middle">
            <Col span={1}>
              <div style={paidIn === 0 ? styles.bluePoint : styles.greyPoint} />
            </Col>
            <Col span={22}>
              <div style={paidIn === 0 ? styles.blueLine : styles.greyLine} />
            </Col>
            <Col span={1}>
              <div style={paidIn === 0 ? styles.bluePoint : styles.greyPoint} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row align="middle">
        <Table
          rowKey="id_cobro"
          pagination={
            { hideOnSinglePage: true }
          }
          size="small"
          loading={loading}
          columns={columns}
          dataSource={reservation.transacciones}
        />
      </Row>
      <Row>
        {getWritePermissionByTab(ConstantsRoutes.PAYMENTS) ? (
          <Col style={{ marginLeft: 8 }}>
            <Button
              className="quedice"
              type="primary"
              disabled={!getWritePermissionByTab(ConstantsRoutes.PAYMENTS)}
              icon={<PlusOutlined />}
              onClick={() => {
                setVisible(true);
              }}
              style={styles.buttonPrimary}
            >
              {t('text_button_new_paybylink')}
            </Button>
          </Col>
        ) : <div />}
        {getWritePermissionByTab(ConstantsRoutes.PAYMENTS) ? (
          <Col style={{ marginLeft: 8 }}>
            <Button
              className="quedice"
              type="primary"
              disabled={!getWritePermissionByTab(ConstantsRoutes.PAYMENTS)}
              icon={<PlusOutlined />}
              onClick={() => {
                setVisibleTpvManual(true);
              }}
              style={styles.buttonPrimary}
            >
              {t('text_button_pay_tpv_manual')}
            </Button>
          </Col>
        ) : <div />}
      </Row>
    </div>
  );
}

ReservationPays.defaultProps = {
  reservation: undefined
};

ReservationPays.propTypes = {
  reservation: PropTypes.shape({
    reservation_id: PropTypes.string,
    gross: PropTypes.string,
    currency: PropTypes.string,
    total_pagado: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    transacciones: PropTypes.arrayOf(PropTypes.shape({
      fecha_transaccion: PropTypes.string,
    })),
    hotel: PropTypes.string,
    usuario: PropTypes.string,
    email: PropTypes.string,
    pendiente_pago: PropTypes.string,

  }),
};
