import ConstantsConciliations from './conciliationsConstants';

export const actionGetFiltersConciliations = () => ({
  type: ConstantsConciliations.GET_FILTERS_CONCILIATIONS,
});

export const actionGetFiltersConciliationsResult = (result, msg, filters) => ({
  type: ConstantsConciliations.GET_FILTERS_CONCILIATIONS_RESULT,
  result,
  msg,
  filters,
});

export const actionGetConciliations = (
  hotel,
  facturationGroup,
  dateCreateStart,
  dateCreateEnd,
  conciliationsState,
  chain,
  numberLocator,
  externalNumberLocator
) => ({
  type: ConstantsConciliations.GET_CONCILIATIONS,
  hotel,
  facturationGroup,
  dateCreateStart,
  dateCreateEnd,
  conciliationsState,
  chain,
  numberLocator,
  externalNumberLocator
});

export const actionConcilitiationsCurrentFiltersValue = (currentFilters) => ({
  type: ConstantsConciliations.CONCILIATIONS_CURRENT_FILTERS,
  currentFilters
});

export const actionGetConciliationsResult = (result, msg, conciliations) => ({
  type: ConstantsConciliations.GET_CONCILIATIONS_RESULT,
  result,
  msg,
  conciliations,
});

export const actionAddNewPreconciliation = (name, companies, dateCreateStart, dateCreateEnd) => ({
  type: ConstantsConciliations.ADD_NEW_PRECONCILIATION,
  name,
  companies,
  dateCreateStart,
  dateCreateEnd
});

export const actionAddNewPreconciliationResult = (result, msg, preconciliation) => ({
  type: ConstantsConciliations.ADD_NEW_PRECONCILIATION_RESULT,
  preconciliation,
  result,
  msg,
});

export const actionClearNewPreconciliationResult = () => ({
  type: ConstantsConciliations.CLEAR_NEW_PRECONCILIATION_RESULT,
});

export const actionGetCompanyByChain = (
  chain,
) => ({
  type: ConstantsConciliations.GET_COMPANY_BY_CHAIN,
  chain,
});

export const actionGetCompanyByChainResult = (result, msg, companies) => ({
  type: ConstantsConciliations.GET_COMPANY_BY_CHAIN_RESULT,
  result,
  msg,
  companies
});

export const actionClearCompanies = () => ({
  type: ConstantsConciliations.CLEAR_COMPANIES,
});

export const actionGetPreBillPdf = (idJob, idHotel, pdfName) => ({
  type: ConstantsConciliations.GET_PDF_PREBILL,
  idJob,
  idHotel,
  pdfName
});

export const actionCancelConciliationResult = (result, msg) => ({
  type: ConstantsConciliations.CANCEL_CONCILIATION_RESULT,
  result,
  msg,
});

export const actionCancelConciliation = (idConciliation, cancelConciliationComment) => ({
  type: ConstantsConciliations.CANCEL_CONCILIATION,
  idConciliation,
  cancelConciliationComment
});

export const actionClearResultAfterRefresh = () => ({
  type: ConstantsConciliations.CLEAR_CANCEL_REQUEST_RESULT,
});

export const actionSendPreBill = (idJob, idHotel, comment) => ({
  type: ConstantsConciliations.SEND_PREBILL,
  idJob,
  idHotel,
  comment
});

export const actionSendPreBillResult = (result, msg) => ({
  type: ConstantsConciliations.SEND_PREBILL_RESULT,
  result,
  msg
});

export const actionGetAllPreBillPdf = (idJob, pdfName) => ({
  type: ConstantsConciliations.GET_ALL_PDF_PREBILL,
  idJob,
  pdfName
});

export const actionSendAllPreBill = (idJob, comment) => ({
  type: ConstantsConciliations.SEND_ALL_PREBILL,
  idJob,
  comment
});

export const actionSendAllBill = (idJob, comment) => ({
  type: ConstantsConciliations.SEND_ALL_BILL,
  idJob,
  comment
});

export const actionSendAllBillResult = (result, msg) => ({
  type: ConstantsConciliations.SEND_ALL_BILL_RESULT,
  result,
  msg
});

export const actionPrevisualizeConciliation = (name, companies, dateCreateStart, dateCreateEnd) => ({
  type: ConstantsConciliations.PREVISUALIZE_CONCILIATION,
  name,
  companies,
  dateCreateStart,
  dateCreateEnd
});

export const actionPrevisualizeConciliationResult = (result, msg, logs) => ({
  type: ConstantsConciliations.PREVISUALIZE_CONCILIATION_RESULT,
  logs,
  result,
  msg,
});

export const actionClearConciliationPrevisualizationResult = () => ({
  type: ConstantsConciliations.CLEAR_NEW_CONCILIATION_PREVISUALIZAATION_RESULT,
});

export const actionGetSalePdf = (idJob, pdfName) => ({
  type: ConstantsConciliations.GET_SALE_PDF,
  idJob,
  pdfName
});
