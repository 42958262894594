import {
  call, takeEvery, put, select
} from 'redux-saga/effects';
import qs from 'qs';
import i18n from '../assets/language/i18n';
import {
  actionLoading,
} from '../store/reducers/loading/loadingActions';

import {request} from './axiosConfig';
import ConstantsConciliations from '../store/reducers/conciliations/conciliationsConstants';

import {
  actionGetConciliations,
  actionGetConciliationsResult,
  actionGetFiltersConciliationsResult,
  actionGetCompanyByChainResult,
  actionAddNewPreconciliationResult,
  actionCancelConciliationResult,
  actionSendPreBillResult,
  actionSendAllBillResult,
  actionConcilitiationsCurrentFiltersValue,
  actionPrevisualizeConciliationResult
} from '../store/reducers/conciliations/conciliationsActions';

const base64Decode = (base64) => {
  const binaryString = window.atob(base64); // el pdf viene en base64
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

function getFiltersConciliationsApi() {
  return request.post('/job/getFiltros', new FormData())
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetFiltersConciliations() {
  const filters = {
    hotels: [],
    conciliationStates: [],
    chains: [],
    facturationGroups: [],
  };
  try {
    const { status, data } = yield call(getFiltersConciliationsApi);
    if (status === 200) {
      filters.hotels = data.hoteles?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.conciliationStates = data.estado?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.chains = data.cadena_hotelera?.sort(
        (a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language),
      );
      filters.facturationGroups = data.empresa?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      yield put(actionGetFiltersConciliationsResult(true, null, filters));
    } else {
      yield put(actionGetFiltersConciliationsResult(false, i18n.t('error_get_filters'), filters));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetFiltersConciliationsResult(false, i18n.t('error_get_filters'), filters));
  }

  yield put(actionLoading(false));
}

function getConciliationsApi(values) {
  const bodyData = {
    cadena_hotelera: values.chain ? values.chain : undefined,
    fecha_inicio: values.dateCreateStart ? values.dateCreateStart : undefined,
    fecha_inicio_fin: values.dateCreateEnd ? values.dateCreateEnd : undefined,
    estado: values.conciliationsState ? values.conciliationsState : undefined,
    codigo_hotel: values.hotel ? values.hotel : undefined,
    empresa: values.facturationGroup ? values.facturationGroup : undefined,
    localizador_reservas: values.numberLocator ? values.numberLocator : undefined,
    localizador_agencia: values.externalNumberLocator ? values.externalNumberLocator : undefined,
  };

  return request.post(
    '/job/getJobs',
    qs.stringify(bodyData),
  )
    .then((response) => {
      const { status, data } = response;
      const result = data.jobs?.map((conciliation) => (
        {
          ...conciliation,
          fecha_inicio: conciliation.fecha_inicio === null ? '' : new Date(conciliation.fecha_inicio),
          fecha_inicio_string: conciliation.fecha_inicio,
          fecha_final: conciliation.fecha_final === null ? '' : new Date(conciliation.fecha_final),
          fecha_final_string: conciliation.fecha_final,
          prefacturas: conciliation.prefacturas === undefined ? [] : conciliation.prefacturas,
        }
      ));
      return { status, result };
    })
    .catch((error) => error.response);
}

function* generateGetConciliations(values) {
  try {
    const { status, result } = yield call(getConciliationsApi, values);
    if (status === 200) {
      const conciliations = result;

      delete values.type;
      yield put(actionConcilitiationsCurrentFiltersValue(values));
      yield put(actionGetConciliationsResult(true, null, conciliations));
    } else {
      yield put(actionGetConciliationsResult(false, i18n.t('error_get_conciliations'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetConciliationsResult(false, i18n.t('error_get_conciliations'), []));
  }

  yield put(actionLoading(false));
}

function getCompanyByChainApi(values) {
  const data = {};

  if (values.chain) {
    data.id_cadena = values.chain;
  }

  return request.post(
    '/job/getEmpresasByCadena',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* getCompanyByChain(values) {
  try {
    const { status, data } = yield call(getCompanyByChainApi, values);
    if (status === 200) {
      yield put(actionGetCompanyByChainResult(true, null, data.empresas));
    } else {
      yield put(actionGetCompanyByChainResult(false, i18n.t('error_get_companies'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetCompanyByChainResult(false, i18n.t('error_get_companies'), []));
  }
  yield put(actionLoading(false));
}

// TODO remove this once the conciliation is working with async processes
function createPreconciliationApi(values) {
  const data = {
    id_empresa: values.companies ? values.companies : [],
    nombre: values.name ? values.name : undefined,
    fecha_checkin: values.dateCreateStart ? values.dateCreateStart : undefined,
    fecha_checkin_final: values.dateCreateEnd ? values.dateCreateEnd : undefined,
  };

  return request.post(
    '/job/initPreconciliacion',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* createPreconciliation(values) {
  try {
    const { status, data } = yield call(createPreconciliationApi, values);
    if (status === 200) {
      yield put(actionAddNewPreconciliationResult(true, null, data));
    } else {
      yield put(actionAddNewPreconciliationResult(false, i18n.t('error_create_preconciliation'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionAddNewPreconciliationResult(false, i18n.t('error_create_preconciliation'), []));
  }
  yield put(actionLoading(false));
}

function getPdfPreBillApi(values) {
  const data = {
    id_job: values.idJob,
    id_hotel: values.idHotel,
  };

  return request.post(
    '/mpdf/getPrefactura',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* getPdfPreBill(values) {
  try {
    const { status, data } = yield call(getPdfPreBillApi, values);
    if (status === 200) {
      const bytes = base64Decode(data);
      const url = window.URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
      const link = document.createElement('a');

      link.setAttribute('download', `${values.pdfName}.pdf`);
      link.href = url;

      document.body.appendChild(link);

      link.click();
    }
  } catch (error) {
    console.log(error);
  }
  yield put(actionLoading(false));
}

function cancelConciliationApi(values) {
  const data = {
    id_job: values.idConciliation,
    mensaje: values.cancelConciliationComment
  };

  return request.post(
    '/arreglo/cancelarJob',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* cancelConciliation(values) {
  try {
    yield put(actionLoading(true));
    const { status } = yield call(cancelConciliationApi, values);
    if (status === 200) {
      yield put(actionCancelConciliationResult(true, null));

      const filters = yield select((state) => state.reducerConciliationCurrentFilters);
      yield put(actionGetConciliations(
        filters.hotel,
        filters.facturationGroup,
        filters.dateCreateStart,
        filters.dateCreateEnd,
        filters.conciliationsState,
        filters.chain
      ));
    } else {
      yield put(actionCancelConciliationResult(false, i18n.t('error_cancel_conciliation'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionCancelConciliationResult(false, i18n.t('error_cancel_conciliation'), []));
  }
  yield put(actionLoading(false));
}

function sendPreBillApi(values) {
  const parameters = {};
  parameters.mensaje = values.comment;
  parameters.id_job = values.idJob;
  if (values.idHotel !== null) {
    parameters.id_hotel = values.idHotel;
  }

  return request.post(
    '/mpdf/sendPrefactura',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* sendPreBill(values) {
  try {
    yield put(actionLoading(true));
    const { status } = yield call(sendPreBillApi, values);
    if (status === 200) {
      yield put(actionSendPreBillResult(true, i18n.t('text_send_pre_bill')));

      const filters = yield select((state) => state.reducerConciliationCurrentFilters);
      yield put(actionGetConciliations(
        filters.hotel,
        filters.facturationGroup,
        filters.dateCreateStart,
        filters.dateCreateEnd,
        filters.conciliationsState,
        filters.chain
      ));
    } else {
      yield put(actionSendPreBillResult(false, i18n.t('error_send_pre_bill')));
    }
  } catch (error) {
    console.log(error);
    yield put(actionSendPreBillResult(false, i18n.t('error_send_pre_bill')));
  }
  yield put(actionSendPreBillResult(false, null));
  yield put(actionLoading(false));
}

function getAllPdfPreBillApi(values) {
  const data = {
    id_job: values.idJob,
  };

  return request.post(
    '/mpdf/preVisualizeFactura',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* getAllPdfPreBill(values) {
  try {
    const { status, data } = yield call(getAllPdfPreBillApi, values);
    if (status === 200) {
      const bytes = base64Decode(data);
      const url = window.URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
      const link = document.createElement('a');

      link.setAttribute('download', `${values.pdfName}.pdf`);
      link.href = url;

      document.body.appendChild(link);

      link.click();
    }
  } catch (error) {
    console.log(error);
  }
  yield put(actionLoading(false));
}

function sendAllBillApi(values) {
  const parameters = {};
  parameters.mensaje = values.comment;
  parameters.id_job = values.idJob;

  return request.post(
    '/mpdf/initFactura',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* sendAllBill(values) {
  try {
    yield put(actionLoading(true));
    const { status } = yield call(sendAllBillApi, values);
    if (status === 200) {
      yield put(actionSendAllBillResult(true, i18n.t('text_send_bill')));
    } else {
      yield put(actionSendAllBillResult(false, i18n.t('error_send_bill')));
    }
  } catch (error) {
    console.log(error);
    yield put(actionSendAllBillResult(false, i18n.t('error_send_bill')));
  }
  yield put(actionSendAllBillResult(false, null));
  yield put(actionLoading(false));
}

function previsualizeConciliationApi(values) {
  const data = {
    id_empresa: values.companies ? values.companies : [],
    nombre: values.name ? values.name : undefined,
    fecha_checkin: values.dateCreateStart ? values.dateCreateStart : undefined,
    fecha_checkin_final: values.dateCreateEnd ? values.dateCreateEnd : undefined,
  };

  return request.post(
    '/job/preVisualizar',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* previsualizeConciliation(values) {
  try {
    const { status, data } = yield call(previsualizeConciliationApi, values);
    if (status === 200) {
      yield put(actionPrevisualizeConciliationResult(true, null, data));
    } else {
      yield put(actionPrevisualizeConciliationResult(false, i18n.t('error_create_preconciliation'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionPrevisualizeConciliationResult(false, i18n.t('error_create_preconciliation'), []));
  }
  yield put(actionLoading(false));
}

function getSalePdfApi(values) {
  const data = {
    id_job: values.idJob,
  };

  return request.post(
    '/mpdf/getLiquidacion',
    qs.stringify(data)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* getSalePdf(values) {
  try {
    const { status, data } = yield call(getSalePdfApi, values);
    if (status === 200) {
      const bytes = base64Decode(data);
      const url = window.URL.createObjectURL(new Blob([bytes], { type: 'application/pdf' }));
      const link = document.createElement('a');

      link.setAttribute('download', `${values.pdfName}.pdf`);
      link.href = url;

      document.body.appendChild(link);

      link.click();
    }
  } catch (error) {
    console.log(error);
  }
  yield put(actionLoading(false));
}

export default function* conciliationSagas() {
  yield takeEvery(
    ConstantsConciliations.GET_FILTERS_CONCILIATIONS,
    generateGetFiltersConciliations
  );
  yield takeEvery(ConstantsConciliations.GET_CONCILIATIONS, generateGetConciliations);
  yield takeEvery(ConstantsConciliations.GET_COMPANY_BY_CHAIN, getCompanyByChain);
  yield takeEvery(ConstantsConciliations.ADD_NEW_PRECONCILIATION, createPreconciliation);
  yield takeEvery(ConstantsConciliations.GET_PDF_PREBILL, getPdfPreBill);
  yield takeEvery(ConstantsConciliations.CANCEL_CONCILIATION, cancelConciliation);
  yield takeEvery(ConstantsConciliations.SEND_PREBILL, sendPreBill);
  yield takeEvery(ConstantsConciliations.GET_ALL_PDF_PREBILL, getAllPdfPreBill);
  yield takeEvery(ConstantsConciliations.SEND_ALL_PREBILL, sendPreBill);
  yield takeEvery(ConstantsConciliations.SEND_ALL_BILL, sendAllBill);
  yield takeEvery(ConstantsConciliations.PREVISUALIZE_CONCILIATION, previsualizeConciliation);
  yield takeEvery(ConstantsConciliations.GET_SALE_PDF, getSalePdf);
}
