import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Drawer,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import {
  CloseCircleOutlined,
  CommentOutlined,
  DisconnectOutlined,
  UpCircleOutlined,
  FileExcelOutlined,
  LinkOutlined,
  LoginOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import moment from 'moment';
import i18n from '../../../assets/language/i18n';
import FilterInvitations from './FilterInvitations';
import Colors from '../../../assets/colors/Colors';
import ConstantsInvitationStates from '../../../constants/ConstantsInvitationStates';
import {
  getCurrencyCode,
  getStatePayInvitation,
  getWritePermissionByTab
} from '../../../utils/Utils';
import actionIndexActive from '../../../store/reducers/home/homeActions';
import { actionSetReservation } from '../../../store/reducers/payments/paymentsActions';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';
import InvitationsHistory from './InvitationsHistory';
import NewInvitationForm from './NewInvitationForm';

import {
  useGetFiltersInvitationsApiQuery,
  useLazyGetInvitationsApiQuery,
  useDisableInvitationApiMutation,
  useActivateInvitationApiMutation,
  useSendInvitationApiMutation,
  useCreateInvitationApiMutation
} from '../../../services/invitations';

const { Text } = Typography;

const styles = {
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW
  },
  textValueOrangeColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW
  },
  icon: {
    color: Colors.PRIMARY_COLOR
  },
  iconActivate: {
    color: Colors.TEXT_GREEN
  },
  iconDisable: {
    color: Colors.TEXT_RED
  },
  textTitlePopconfirm: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium'
  },
  textOkButtonPopconfirm: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    color: 'white'
  },
  textCancelButtonPopconfirm: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14
  }
};

export default function Invitations() {
  const [visible, setVisible] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [payConceptSelected, setPayConceptSelected] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const invitationsAppliedFilters = useSelector(
    (state) => state.invitations.appliedFilters
  );

  const loading = useSelector((state) => state.reducerLoading);

  const jwtData = useSelector((state) => state.reducerJwtData);

  const isCubatourProfile = jwtData?.userInfo?.permisos?.tipo_agente === 'ctt';

  const { data: applyFilters, isFetching } = useGetFiltersInvitationsApiQuery();
  const [
    disableInvitation,
    {
      data: disableInvitationResult,
      isLoading: isLoadingDisableInvitation,
      error: errorDisableInvitation
    }
  ] = useDisableInvitationApiMutation();

  const [
    activateInvitation,
    {
      data: activateInvitationResult,
      isLoading: isLoadingActivateInvitation,
      error: errorActivateInvitation
    }
  ] = useActivateInvitationApiMutation();

  const [
    sendInvitation,
    {
      data: sendInvitationResult,
      isLoading: isLoadingSendInvitation,
      error: errorSendInvitation
    }
  ] = useSendInvitationApiMutation();

  const [
    createInvitation,
    {
      data: createInvitationResult,
      isLoading: isLoadingCreateInvitation,
      error: errorCreateInvitation
    }
  ] = useCreateInvitationApiMutation();

  const [
    getInvitations,
    {
      data: invitationsData,
      isFetching: isFetchingGetInvitations,
      error: errorGetInvitations
    }
  ] = useLazyGetInvitationsApiQuery();
  const invitations = invitationsData || [];

  useEffect(() => {
    if (errorGetInvitations) {
      message.error(errorGetInvitations.message);
    }
  }, [errorGetInvitations]);

  useEffect(() => {
    if (errorDisableInvitation) {
      message.error(errorDisableInvitation.message);
    }
  }, [errorDisableInvitation]);

  useEffect(() => {
    if (errorActivateInvitation) {
      message.error(errorActivateInvitation.message);
    }
  }, [errorActivateInvitation]);

  useEffect(() => {
    if (errorSendInvitation) {
      message.error(errorSendInvitation.message);
    }
  }, [errorSendInvitation]);

  useEffect(() => {
    if (errorCreateInvitation) {
      message.error(errorCreateInvitation.message);
    }
  }, [errorCreateInvitation]);

  useEffect(() => {
    if (disableInvitationResult?.message) {
      message.success(disableInvitationResult.message);
    }
  }, [disableInvitationResult]);

  useEffect(() => {
    if (activateInvitationResult?.message) {
      message.success(activateInvitationResult.message);
    }
  }, [activateInvitationResult]);

  useEffect(() => {
    if (sendInvitationResult?.message) {
      message.success(sendInvitationResult.message);
    }
  }, [sendInvitationResult]);

  useEffect(() => {
    if (createInvitationResult?.message) {
      message.success(createInvitationResult.message);
    }
  }, [createInvitationResult]);

  useEffect(() => {
    if (invitationsAppliedFilters) {
      getInvitations(invitationsAppliedFilters);
    }
  }, [invitationsAppliedFilters, getInvitations]);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo(null);
  };

  const isLoading = loading
    || isFetching
    || isFetchingGetInvitations
    || isLoadingDisableInvitation
    || isLoadingActivateInvitation
    || isLoadingSendInvitation
    || isLoadingCreateInvitation;

  const handleOk = (values) => {
    createInvitation(values);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const filtersSelected = filteredInfo || {};

  const headers = [
    [
      t('text_id_transaction'),
      isCubatourProfile ? t('text_office') : t('text_hotel'),
      t('text_import'),
      t('text_currency'),
      t('text_destinatary'),
      t('text_email'),
      t('text_external_booking_locator'),
      t('text_date_send'),
      t('text_date_transaction'),
      t('text_status'),
      t('text_issuer_user'),
      t('text_booking_locator'),
      t('hint_concept'),
      t('text_language'),
      t('text_code_terminal'),
      t('text_cancel_date'),
      t('text_init_service_date'),
      t('text_end_service_date'),
      t('text_reservation_state'),
      t('text_channel')
    ]
  ];

  const csvData = [];

  invitations?.forEach((i) => {
    csvData.push({
      id_transaccion: i.id_transaccion,
      hotel: i.hotel,
      importe: Number(i.importe) ?? Number(0.0),
      moneda: i.moneda,
      destinatario: i.destinatario,
      email: i.email,
      id_reserva: i.id_reserva,
      fecha_string: i.fecha,
      fecha_transaccion_string: i.fecha_transaccion,
      estado: i.estado,
      usuario_emisor: i.nombre,
      localizador: i.localizador,
      concepto: i.registro,
      idioma: i.idioma,
      codigo_terminal: i.codigo_terminal,
      fecha_cancelacion: i.fecha_cancelacion,
      fecha_inicio_servicio: i.fecha_inicio_servicio,
      fecha_fin_servicio: i.fecha_fin_servicio,
      estado_reserva: i.estado_reserva,
      tipo_canal: i.tipo_canal
    });
  });

  const idTransactionFilters = [];
  const hotelFilters = [];
  const destinaryFilters = [];
  const emailFilters = [];
  const conceptFilters = [];
  const stateFilters = [];

  invitations?.forEach((invitation) => {
    if (
      invitation.id_transaccion !== null
      && invitation.id_transaccion.length > 0
    ) {
      if (
        !idTransactionFilters.some(
          (transaction) => transaction.value === invitation.id_transaccion
        )
      ) {
        idTransactionFilters.push({
          text: invitation.id_transaccion,
          value: invitation.id_transaccion
        });
      }
    }
    if (
      invitation.codigo_hotel !== null
      && invitation.codigo_hotel.length > 0
    ) {
      if (
        !hotelFilters.some((hotel) => hotel.value === invitation.codigo_hotel)
      ) {
        hotelFilters.push({
          text: invitation.hotel,
          value: invitation.codigo_hotel
        });
      }
    }
    if (
      invitation.destinatario !== null
      && invitation.destinatario.length > 0
    ) {
      if (
        !destinaryFilters.some(
          (destinatary) => destinatary.value === invitation.destinatario
        )
      ) {
        destinaryFilters.push({
          text: invitation.destinatario,
          value: invitation.destinatario
        });
      }
    }
    if (invitation.email !== null && invitation.email.length > 0) {
      if (!emailFilters.some((email) => email.value === invitation.email)) {
        emailFilters.push({
          text: invitation.email,
          value: invitation.email
        });
      }
    }
    if (invitation.localizador !== null && invitation.localizador.length > 0) {
      if (
        !conceptFilters.some(
          (idLocalizador) => idLocalizador.value === invitation.localizador
        )
      ) {
        conceptFilters.push({
          text: invitation.localizador,
          value: invitation.localizador
        });
      }
    }
    if (invitation.id_reserva !== null && invitation.id_reserva.length > 0) {
      if (
        !conceptFilters.some(
          (idReserva) => idReserva.value === invitation.id_reserva
        )
      ) {
        conceptFilters.push({
          text: invitation.id_reserva,
          value: invitation.id_reserva
        });
      }
    }
    if (invitation.estado !== null && invitation.estado.length > 0) {
      if (!stateFilters.some((state) => state.value === invitation.estado)) {
        stateFilters.push({
          text: getStatePayInvitation(invitation.estado),
          value: invitation.estado
        });
      }
    }
  });
  idTransactionFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  hotelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  destinaryFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  emailFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  conceptFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  stateFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));

  const columns = [
    {
      title: <Text className="text">{t('text_id_transaction')}</Text>,
      dataIndex: 'id_transaccion',
      key: 'id_transaccion',
      filteredValue: filtersSelected.id_transaccion || null,
      filters: idTransactionFilters,
      onFilter: (value, record) => record.id_transaccion?.indexOf(value) === 0,
      render: (text, record) => {
        let styleNormal;
        if (record.estado === ConstantsInvitationStates.COBRADO) {
          styleNormal = styles.textValueGreenColumn;
        } else if (record.estado === ConstantsInvitationStates.CANCELADO) {
          styleNormal = styles.textValueRedColumn;
        } else if (record.estado === ConstantsInvitationStates.PENDIENTE_PAGO) {
          styleNormal = styles.textValueYellowColumn;
        } else {
          styleNormal = styles.textValueRedColumn;
        }
        return <Text style={styleNormal}>{text}</Text>;
      }
    },
    {
      title: <Text className="text">{t('text_booking_locator')}</Text>,
      dataIndex: 'localizador',
      key: 'localizador',
      sorter: (a, b) => a.localizador?.localeCompare(b.localizador, i18n.language),
      filteredValue: filtersSelected.localizador || null,
      filters: conceptFilters,
      onFilter: (value, record) => record.localizador?.indexOf(value) === 0,
      render: (text, record) => (
        <Tooltip title={record.registro}>
          <Link to="/payments">
            <Text
              className="text"
              onClick={() => {
                dispatch(actionSetReservation(record.localizador));
                dispatch(actionIndexActive(1));
              }}
            >
              {text}
            </Text>
          </Link>
        </Tooltip>
      )
    },
    {
      title: <Text className="text">{t('text_external_booking_locator')}</Text>,
      dataIndex: 'id_reserva',
      key: 'id_reserva',
      sorter: (a, b) => a.id_reserva?.localeCompare(b.id_reserva, i18n.language),
      filteredValue: filtersSelected.id_reserva || null,
      filters: conceptFilters,
      onFilter: (value, record) => record.id_reserva?.indexOf(value) === 0,
      render: (text, record) => (
        <Tooltip title={record.registro}>
          <Link to="/payments">
            <Text
              className="text"
              onClick={() => {
                dispatch(actionSetReservation(record.id_reserva));
                dispatch(actionIndexActive(1));
              }}
            >
              {text}
            </Text>
          </Link>
        </Tooltip>
      )
    },
    {
      title: <Text className="text">{t('text_destinatary')}</Text>,
      dataIndex: 'destinatario',
      key: 'destinatario',
      sorter: (a, b) => a.destinatario?.localeCompare(b.destinatario, i18n.language),
      filteredValue: filtersSelected.destinatario || null,
      filters: destinaryFilters,
      onFilter: (value, record) => record.destinatario?.indexOf(value) === 0,
      render: (text) => <Text className="text">{text}</Text>
    },
    {
      title: <Text className="text">{t('text_email')}</Text>,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email?.localeCompare(b.email, i18n.language),
      filteredValue: filtersSelected.email || null,
      filters: emailFilters,
      onFilter: (value, record) => record.email?.indexOf(value) === 0,
      render: (text) => <Text className="text">{text}</Text>
    },
    {
      title: <Text className="text">{t('text_import')}</Text>,
      dataIndex: 'importe',
      key: 'importe',
      sorter: (a, b) => Number(a.importe) - Number(b.importe),
      render: (text, record) => (
        <Text className="text">
          {text === 0 ? '' : `${text} ${getCurrencyCode(record.moneda)}`}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_currency')}</Text>,
      dataIndex: 'moneda',
      key: 'moneda',
      sorter: (a, b) => a.moneda?.localeCompare(b.moneda, i18n.language),
      align: 'center',
      render: (text, record) => (
        <Text className="text">{getCurrencyCode(record.moneda)}</Text>
      )
    },

    {
      title: <Text className="text">{t('text_date_send')}</Text>,
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: (a, b) => moment(a.fecha).format('YYYY-MM-DD HH:mm:ss').localeCompare(moment(b.fecha).format('YYYY-MM-DD HH:mm:ss'), i18n.language),
      render: (text) => (
        <Text className="text">
          {!text ? '' : moment(text, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/Y')}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_date_transaction')}</Text>,
      dataIndex: 'fecha_transaccion',
      key: 'fecha_transaccion',
      sorter: (a, b) => moment(a.fecha).format('YYYY-MM-DD HH:mm:ss').localeCompare(moment(b.fecha).format('YYYY-MM-DD HH:mm:ss'), i18n.language),
      render: (text) => (
        <Text className="text">
          {!text ? '' : moment(text, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/Y')}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_status')}</Text>,
      dataIndex: 'estado',
      key: 'estado',
      sorter: (a, b) => a.estado?.localeCompare(b.estado, i18n.language),
      filteredValue: filtersSelected.estado || null,
      filters: stateFilters,
      onFilter: (value, record) => record.estado?.indexOf(value) === 0,
      render: (text) => {
        let styleNormal;
        if (text === ConstantsInvitationStates.COBRADO) {
          styleNormal = styles.textValueGreenColumn;
        } else if (text === ConstantsInvitationStates.CANCELADO) {
          styleNormal = styles.textValueRedColumn;
        } else if (text === ConstantsInvitationStates.PENDIENTE_PAGO) {
          styleNormal = styles.textValueYellowColumn;
        } else {
          styleNormal = styles.textValueRedColumn;
        }
        return <Text style={styleNormal}>{getStatePayInvitation(text)}</Text>;
      }
    },
    {
      title: (
        <Text className="text">
          {isCubatourProfile ? t('text_office') : t('text_hotel')}
        </Text>
      ),
      dataIndex: 'hotel',
      key: 'hotel',
      sorter: (a, b) => a.hotel?.localeCompare(b.hotel, i18n.language),
      filteredValue: filtersSelected.hotel || null,
      filters: hotelFilters,
      onFilter: (value, record) => record.codigo_hotel?.indexOf(value) === 0,
      render: (text) => <Text className="text">{text}</Text>
    },
    {
      title: <Text className="text">{t('text_issuer_user')}</Text>,
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b) => a.nombre?.localeCompare(b.nombre, i18n.language),
      render: (text) => <Text className="text">{text}</Text>
    },
    {
      title: '',
      width: 120,
      render: (record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 }
        },
        children: (
          <Row>
            <Tooltip title={t('hint_concept')}>
              <Button
                type="link"
                htmlType="button"
                icon={<CommentOutlined style={styles.icon} />}
                onClick={() => {
                  setPayConceptSelected(record.registro);
                  setVisibleDrawer(true);
                }}
                style={styles.buttonLink}
              />
            </Tooltip>
            <Popconfirm
              placement="bottomRight"
              title={(
                <Text style={styles.textTitlePopconfirm}>
                  {t('text_ask_send_invitation')}
                </Text>
              )}
              icon={<QuestionCircleOutlined style={styles.icon} />}
              onConfirm={() => {
                sendInvitation(record.id_linktopay);
              }}
              okText={(
                <Text style={styles.textOkButtonPopconfirm}>
                  {t('text_yes')}
                </Text>
              )}
              cancelText={(
                <Text style={styles.textCancelButtonPopconfirm}>
                  {t('text_no')}
                </Text>
              )}
            >
              <Tooltip title={t('text_send_invitation')}>
                <Button
                  type="link"
                  htmlType="button"
                  disabled={
                    record.estado === ConstantsInvitationStates.COBRADO
                    || record.estado === ConstantsInvitationStates.CANCELADO
                  }
                  icon={<LoginOutlined style={styles.icon} />}
                  style={styles.buttonLink}
                />
              </Tooltip>
            </Popconfirm>
            {record.estado === ConstantsInvitationStates.CANCELADO
            || record.estado === ConstantsInvitationStates.CADUCADO ? (
              <Tooltip title={t('text_activate_invitation')}>
                <Button
                  type="link"
                  htmlType="button"
                  disabled={
                    record.estado === ConstantsInvitationStates.COBRADO
                    || record.estado
                      === ConstantsInvitationStates.PENDIENTE_PAGO
                    || record.estado === ConstantsInvitationStates.ERROR_PAGO
                  }
                  icon={<LinkOutlined style={styles.iconActivate} />}
                  onClick={() => {
                    activateInvitation(record.id_linktopay);
                  }}
                  style={styles.buttonLink}
                />
              </Tooltip>
              ) : (
                <Tooltip title={t('text_disable_invitation')}>
                  <Button
                    type="link"
                    htmlType="button"
                    disabled={
                    record.estado === ConstantsInvitationStates.COBRADO
                    || record.estado === ConstantsInvitationStates.CANCELADO
                  }
                    icon={<DisconnectOutlined style={styles.iconDisable} />}
                    onClick={() => {
                      disableInvitation(record.id_linktopay);
                    }}
                    style={styles.buttonLink}
                  />
                </Tooltip>
              )}
          </Row>
        )
      })
    }
  ];

  return (
    <Spin spinning={isLoading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          <Modal
            title={(
              <Text style={styles.textTitlePopconfirm}>
                {t('new_pay_by_link')}
              </Text>
            )}
            open={visible}
            footer={null}
            onCancel={handleCancel}
            closeIcon={<CloseCircleOutlined style={styles.icon} />}
          >
            <NewInvitationForm handleSubmit={handleOk} />
          </Modal>
          <FilterInvitations />
          <Row justify="end">
            {getWritePermissionByTab(ConstantsRoutes.INVITATIONS) ? (
              <Col style={{ marginRight: 16 }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="small"
                  disabled={
                    !getWritePermissionByTab(ConstantsRoutes.INVITATIONS)
                  }
                  onClick={() => {
                    setVisible(true);
                  }}
                  className="btn btn-h-auto text text-w-bold px-3 py-2"
                >
                  {t('text_button_add')}
                </Button>
              </Col>
            ) : (
              <div />
            )}
            <Col style={{ marginRight: 16 }}>
              <Button
                onClick={clearFilters}
                size="small"
                disabled={invitations.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_clear_filters')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                size="small"
                disabled={invitations.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  const wb = XLSX.utils.book_new();
                  const ws = XLSX.utils.json_to_sheet([]);
                  XLSX.utils.sheet_add_aoa(ws, headers);

                  XLSX.utils.sheet_add_json(ws, csvData, {
                    origin: 'A2',
                    skipHeader: true
                  });
                  XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                  XLSX.writeFile(wb, 'invitaciones.xlsx');
                }}
              >
                {t('text_button_export')}
              </Button>
            </Col>
          </Row>
          <Drawer
            title={t('hint_concept')}
            placement="right"
            open={visibleDrawer}
            width={500}
            onClose={() => {
              setVisibleDrawer(false);
            }}
          >
            <Text className="text">{payConceptSelected}</Text>
          </Drawer>
          <Table
            scroll={{ x: 2500 }}
            rowKey="id_linktopay"
            style={{ width: '100%' }}
            pagination={{
              total: invitations.length,
              pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500']
            }}
            size="small"
            loading={loading}
            columns={columns}
            dataSource={invitations}
            onChange={handleChange}
            footer={() => (
              <Row>
                {getWritePermissionByTab(ConstantsRoutes.INVITATIONS) ? (
                  <Col style={{ marginRight: 16 }}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="small"
                      disabled={
                        !getWritePermissionByTab(ConstantsRoutes.INVITATIONS)
                      }
                      onClick={() => {
                        setVisible(true);
                      }}
                      className="btn btn-h-auto text text-w-bold px-3 py-2"
                    >
                      {t('text_button_add')}
                    </Button>
                  </Col>
                ) : (
                  <div />
                )}
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearFilters}
                    size="small"
                    disabled={invitations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon={<FileExcelOutlined />}
                    size="small"
                    disabled={invitations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                    onClick={() => {
                      const wb = XLSX.utils.book_new();
                      const ws = XLSX.utils.json_to_sheet([]);
                      XLSX.utils.sheet_add_aoa(ws, headers);
                      XLSX.utils.sheet_add_json(ws, csvData, {
                        origin: 'A2',
                        skipHeader: true
                      });
                      XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                      XLSX.writeFile(wb, 'invitaciones.xlsx');
                    }}
                  >
                    {t('text_button_export')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + invitations.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
            expandable={{
              expandRowByClick: true,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (record?.historial?.length > 0) {
                  return expanded ? (
                    <UpCircleOutlined
                      onClick={(e) => onExpand(record, e)}
                      style={styles.icon}
                    />
                  ) : (
                    <RightCircleOutlined
                      onClick={(e) => onExpand(record, e)}
                      style={styles.icon}
                    />
                  );
                }
                return null;
              },
              expandedRowRender: (record) => (
                <InvitationsHistory invitation={record} />
              ),
              rowExpandable: (record) => record?.historial?.length > 0
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
}
