import ConstantsPreConciliation from './preConciliationConstants';
import StoreGlobalConstants from '../storeGlobalConstants';

export const reducerFiltersPreConciliation = (state = {
  result: false,
  msg: null,
  hotels: [],
  channels: [],
  chains: [],
  payForms: [],
  reservationStates: [],
  payStates: [],
  concepts: [],
  validations: [],
  currencies: [],
  clients: [],
  crs: []
}, action = {}) => {
  switch (action.type) {
    case ConstantsPreConciliation.GET_FILTERS_PRE_CONCILIATION_RESULT:
      return {
        ...state,
        result: action.result,
        msg: action.msg,
        hotels: action.filters.hotels,
        channels: action.filters.channels,
        chains: action.filters.chains,
        payForms: action.filters.payForms,
        reservationStates: action.filters.reservationStates,
        payStates: action.filters.payStates,
        concepts: action.filters.concepts,
        currencies: action.filters.currencies,
        validations: action.filters.validations,
        clients: action.filters.clients,
        crs: action.filters.crs,
      };
    case StoreGlobalConstants.CLEAR_DATA:
      return {
        ...state,
        result: false,
        msg: null,
        hotels: [],
        channels: [],
        chains: [],
        payForms: [],
        reservationStates: [],
        payStates: [],
        concepts: [],
        validations: [],
        currencies: [],
        clients: [],
      };
    default:
      return state;
  }
};

export const reducerPreConciliationReservations = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsPreConciliation.GET_PRE_CONCILIATION_RESERVATIONS_RESULT:
      return action.reservations;
    default:
      return state;
  }
};

export const reducerApplyRecalificationReservationsResult = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsPreConciliation.APPLY_RECALIFICATION_RESERVATIONS_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerChangeStatusReservationsResult = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsPreConciliation.CHANGE_STATUS_RESERVATIONS_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerPreConciliationResumeResult = (state = {}, action = {}) => {
  switch (action.type) {
    case ConstantsPreConciliation.GET_RESUME_PRE_CONCILIATION_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerPreConciliationCommentResult = (state = {}, action = {}) => {
  switch (action.type) {
    case ConstantsPreConciliation.GET_COMMENT_PRE_CONCILIATION_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerPreConciliationSendCommentResult = (state = {}, action = {}) => {
  switch (action.type) {
    case ConstantsPreConciliation.SEND_COMMENT_RESERVATIONS_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerDeleteReclasificationReservationsResult = (state = {}, action = {}) => {
  switch (action.type) {
    case ConstantsPreConciliation.DELETE_RECALIFICATION_RESERVATIONS_RESULT:
      return action;
    default:
      return state;
  }
};
