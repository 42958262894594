import Colors from '../../../../assets/colors/Colors';

export default {
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW,
  },
  textValueHighGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
};
