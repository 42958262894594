import React from 'react';
import 'antd/dist/antd.less';
import { Navigate, useLocation, } from 'react-router-dom';
import Cookies from 'js-cookie';
import Payments from './payments';
import Invitations from './invitations';
import NewInvitationView from './invitations/NewInvitationView';
import ConstantsValue from '../../constants/ConstantsValue';
import PreConciliation from './preConciliation';
import ConstantsRoutes from '../../constants/ConstantsRoutes';
import Conciliations from './conciliations';
import Conciliation from './conciliations/conciliation';
import TpvManual from './tpvManual';
import TpvTransactions from './tpvTransactions';

import CollectionRequests from './collectionRequests';
import PaymentRequests from './paymentRequests';

import {getDefaultView } from '../../utils/Utils';

export default function RoutesAuthYes() {
  const location = useLocation();

  if (Cookies.get(ConstantsValue.JWT) === undefined
    || Cookies.get(ConstantsValue.JWT) === null) {
    return <Navigate to="/login" replace />;
  }
  switch (location.pathname) {
    case ConstantsRoutes.PAYMENTS:
      return <Payments />;
    case ConstantsRoutes.INVITATIONS:
      return <Invitations />;
    case ConstantsRoutes.INVITATION_ADD:
      return <NewInvitationView />;
    case ConstantsRoutes.PRE_CONCILIATION:
      return <PreConciliation />;
    case ConstantsRoutes.CONCILIATIONS:
      return <Conciliations />;
    case ConstantsRoutes.CONCILIATION:
      return <Conciliation />;
    case ConstantsRoutes.TPV_MANUAL:
      return <TpvManual />;
    case ConstantsRoutes.TPV_TRANSACTIONS:
      return <TpvTransactions />;
    case ConstantsRoutes.COLLECTION_REQUESTS:
      return <CollectionRequests />;
    case ConstantsRoutes.PAYMENT_REQUESTS:
      return <PaymentRequests />;
    default:
      return <Navigate to={getDefaultView()} replace />;
  }
}
