import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Input,
  message,
  Row,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CheckCircleOutlined,
  PlusOutlined,
  SearchOutlined, QuestionCircleOutlined,
  CloseCircleOutlined,
  RightCircleOutlined,
  UpCircleOutlined,
  FilePdfOutlined,
  UploadOutlined,
  FileExcelOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.less';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import * as XLSX from 'xlsx';
import i18n from '../../../assets/language/i18n';
import FilterPaymentRequest from './FilterPaymentRequest';
import Colors from '../../../assets/colors/Colors';
import NewPaymentRequestModal from './NewPaymentRequestModal';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';
import { getWritePermissionByTab } from '../../../utils/Utils';
import ConstantsPaymentRequestsStates from '../../../constants/ConstantsPaymentRequestsStates';
import styles from './styles/stylesIndex';
import Attachments from './Attachments';
import AttachmentModal from './AttachmentModal';
import {
  useLazyGetPaymentRequestsApiQuery, useGetFiltersPaymentRequestsApiQuery,
  useChangePaymentRequestStatusApiMutation, useLazyDownloadPaymentRequestPdfQuery, useLazyDownloadFinalPaymentRequestQuery
} from '../../../services/paymentRequests';

const { Text } = Typography;

export default function Conciliations() {
  const [showModalNewPaymentRequest, setShowModalNewPaymentRequest] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [showModalAttachment, setShowModalAttachment] = useState(false);
  const [paymentRequestSelected, setPaymentRequestSelected] = useState(null);

  const { t } = useTranslation();
  const loading = useSelector((state) => state.reducerLoading);

  const [
    getPaymentRequests,
    {
      data: paymentRequestsData,
      isFetching: isFetchingGetPaymentRequests,
      error: errorGetPaymentRequests
    }
  ] = useLazyGetPaymentRequestsApiQuery();
  const paymentRequests = paymentRequestsData || [];

  const [
    changeStatusPaymentRequest,
    {
      data: dataChangeStatusPaymentRequest,
      isLoading: isLoadingChangeStatusPaymentRequest,
      isSuccess: isSuccessChangeStatusPaymentRequest,
      error: errorChangeStatusPaymentRequest
    }
  ] = useChangePaymentRequestStatusApiMutation({ });

  const {
    data: filtersPaymentRequestOptions
  } = useGetFiltersPaymentRequestsApiQuery();

  const [
    downloadPdfFile,
    {
      isFetching: isDownloadingPdfFile,
      error: errorDownloadingPdfFile
    }
  ] = useLazyDownloadPaymentRequestPdfQuery();

  const [
    downloadPaymentRequest,
    {
      isFetching: isDownloadingPaymentRequest,
      error: errorDownloadingPaymentRequest
    }
  ] = useLazyDownloadFinalPaymentRequestQuery();

  const paymentRequestsAppliedFilters = useSelector(
    (state) => state.paymentRequests.appliedFilters
  );

  useEffect(() => {
    if (paymentRequestsAppliedFilters) {
      getPaymentRequests(paymentRequestsAppliedFilters);
    }
  }, [paymentRequestsAppliedFilters, getPaymentRequests]);

  useEffect(() => {
    if (errorGetPaymentRequests) {
      message.error(errorGetPaymentRequests.message);
    }
    if (isSuccessChangeStatusPaymentRequest) {
      message.success(dataChangeStatusPaymentRequest?.message);
    }
  }, [errorGetPaymentRequests, isSuccessChangeStatusPaymentRequest]);

  if (errorChangeStatusPaymentRequest) {
    message.error(errorChangeStatusPaymentRequest.message);
  }

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo(null);
  };

  const isLoading = loading || isFetchingGetPaymentRequests || isLoadingChangeStatusPaymentRequest;

  const filtersSelected = filteredInfo || {};

  const companiesFilters = [];
  const chainsFilters = [];
  const statusFilters = [];

  paymentRequests.forEach((paymentRequest) => {
    if (paymentRequest.id_empresa && !companiesFilters.some((item) => item.value === paymentRequest.id_empresa)) {
      companiesFilters.push({
        text: filtersPaymentRequestOptions.companies.find((item) => item.value === paymentRequest.id_empresa)?.label,
        value: paymentRequest.id_empresa,
      });
    }
    if (paymentRequest.id_cadena && !chainsFilters.some((item) => item.value === paymentRequest.id_cadena)) {
      chainsFilters.push({
        text: filtersPaymentRequestOptions.chains.find((item) => item.id_cadena === paymentRequest.id_cadena)?.nombre_cadena,
        value: paymentRequest.id_cadena,
      });
    }
    if (paymentRequest.estado && !statusFilters.some((item) => item.value === paymentRequest.estado)) {
      statusFilters.push({
        text: paymentRequest.estado,
        value: paymentRequest.estado,
      });
    }
  });

  chainsFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));
  companiesFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));
  statusFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));

  const disabledActions = (row) => row.estado !== ConstantsPaymentRequestsStates.PENDING && row.estado !== ConstantsPaymentRequestsStates.DRAFT;

  const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => {
      const hintSearch = t('text_headline');
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={hintSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            className="input-payment-request-table"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              className="button-primary"
            >
              {t('text_button_filter')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" className="button-default">
              {t('text_button_clear')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? Colors.PRIMARY_COLOR : Colors.ICON_COLOR }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex]?.toString().toLowerCase().includes(value?.toLowerCase()) : ''),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text, record) => {
      const recordStatus = record.estado;
      let styleHigh;
      let styleNormal;
      if (recordStatus === ConstantsPaymentRequestsStates.CLOSED) {
        styleHigh = styles.textValueHighGreenColumn;
        styleNormal = styles.textValueGreenColumn;
      } else if (recordStatus === ConstantsPaymentRequestsStates.CANCEL) {
        styleHigh = styles.textValueHighRedColumn;
        styleNormal = styles.textValueRedColumn;
      } else {
        styleHigh = styles.textValueHighYellowColumn;
        styleNormal = styles.textValueYellowColumn;
      }
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={styleHigh}
            unhighlightStyle={styleNormal}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        );
      } return (
        <Text style={styleNormal}>
          {text}
        </Text>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getExcelHeaders = () => [
    t('text_reference'),
    t('text_chain'),
    t('company'),
    t('source_bank_account'),
    t('text_request_date'),
    t('text_payment_date'),
    t('text_status'),
    t('text_amount'),
    t('adg_profit'),
    t('text_pay_total'),
    t('text_currency'),
    t('destination_bank_account_owner'),
    t('destination_bank'),
    t('destination_bank_account_number'),
    t('destination_bank_address'),
    t('destination_bank_account_swift'),
  ];

  const getExcelData = () => paymentRequests?.map((paymentRequest) => ([
    paymentRequest?.referencia,
    filtersPaymentRequestOptions?.chains.find((item) => item.id === paymentRequest.id_cadena)?.nombre_cadena,
    filtersPaymentRequestOptions?.companies.find((item) => item.value === paymentRequest.id_empresa)?.label,
    paymentRequest?.id_cuenta_bancaria,
    paymentRequest?.fecha,
    paymentRequest?.fecha_pago,
    paymentRequest?.estado,
    paymentRequest?.importe,
    paymentRequest?.importe_honorario,
    paymentRequest?.total_importe_transferir,
    paymentRequest?.moneda,
    paymentRequest?.titular_receptor,
    paymentRequest?.banco_receptor,
    paymentRequest?.cuenta_bancaria_receptor,
    paymentRequest?.direccion_banco_receptor,
    paymentRequest?.swift,

    paymentRequest.descripcion,
  ]
  ));

  const columns = [
    {
      title: <Text className="text">{t('text_reference')}</Text>,
      dataIndex: 'referencia',
      key: 'referencia',
      sorter: {
        compare: (a, b) => a.referencia.localeCompare(b.referencia, i18n.language),
        multiple: 10,
      },
      responsive: ['md'],
      ...getColumnSearchProps('referencia'),
      filteredValue: filtersSelected?.referencia || null
    },
    {
      title: <Text className="text">{t('text_chain')}</Text>,
      dataIndex: 'id_cadena',
      key: 'id_cadena',
      sorter: {
        compare: (a, b) => chainsFilters.find((item) => item.value === a.id_cadena)?.text?.localeCompare(chainsFilters.find((item) => item.value === b.id_cadena)?.text, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.id_cadena || null,
      filters: chainsFilters,
      onFilter: (value, record) => record.id_cadena?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            chainsFilters.find((item) => item.value === text)?.text
          }
        </Text>
      ),
    },

    {
      title: <Text className="text">{t('company')}</Text>,
      dataIndex: 'id_empresa',
      key: 'id_empresa',
      sorter: {
        compare: (a, b) => companiesFilters.find((item) => item.value === a.id_empresa)?.text?.localeCompare(companiesFilters.find((item) => item.value === b.id_empresa)?.text, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.id_empresa || null,
      filters: companiesFilters,
      onFilter: (value, record) => record.id_empresa?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            companiesFilters.find((item) => item.value === text)?.text
          }
        </Text>
      ),
    },

    {
      title: <Text className="text">{t('text_request_date')}</Text>,
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: {
        compare: (a, b) => moment(a.fecha).format('YYYYMMDD').localeCompare(moment(b.fecha).format('YYYYMMDD'), i18n.language),
        multiple: 4,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text ? moment(text).format('DD/MM/YYYY') : ''}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_payment_date')}</Text>,
      dataIndex: 'fecha_pago',
      key: 'fecha_pago',
      sorter: {
        compare: (a, b) => moment(a.fecha).format('YYYYMMDD').localeCompare(moment(b.fecha).format('YYYYMMDD'), i18n.language),
        multiple: 4, // TODO
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text ? moment(text).format('DD/MM/YYYY') : ''}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_amount')}</Text>,
      dataIndex: 'importe',
      key: 'importe',
      sorter: {
        compare: (a, b) => a.importe?.localeCompare(b.importe, i18n.language),
        multiple: 10,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {`${text}`}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_currency')}</Text>,
      dataIndex: 'moneda',
      key: 'moneda',
      sorter: {
        compare: (a, b) => a.moneda.localeCompare(b.moneda, i18n.language),
        multiple: 10,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            text
          }
        </Text>
      ),
    },

    {
      title: <Text className="text">{t('text_status')}</Text>,
      dataIndex: 'estado',
      key: 'estado',
      sorter: {
        compare: (a, b) => a.estado.localeCompare(b.estado, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.estado || null,
      filters: statusFilters,
      onFilter: (value, record) => record.estado?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            text
          }
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_actions')}</Text>,
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (id, record) => (
        <div>
          <Tooltip title={t('download_pdf')}>
            <Button
              type="link"
              htmlType="button"
              icon={<FilePdfOutlined style={styles.icon} />}
              style={styles.buttonLink}
              loading={isDownloadingPdfFile}
              onClick={() => {
                downloadPdfFile({id, name: `${t('payment_request')}-${record?.referencia}.pdf`});
              }}
            />
          </Tooltip>
          <Tooltip title={t('download_payment_request')}>
            <Button
              type="link"
              htmlType="button"
              icon={<DownloadOutlined style={styles.icon} />}
              disabled={
                record.estado !== ConstantsPaymentRequestsStates.CLOSED
              }
              style={styles.buttonLink}
              loading={isDownloadingPaymentRequest}
              onClick={() => {
                downloadPaymentRequest({id});
              }}
            />
          </Tooltip>
          <Tooltip title={t('attach_file')}>
            <Button
              type="link"
              htmlType="button"
              disabled={
                record.estado !== ConstantsPaymentRequestsStates.DRAFT && record.estado !== ConstantsPaymentRequestsStates.PENDING
              }
              icon={<UploadOutlined style={styles.icon} />}
              style={styles.buttonLink}
              onClick={() => {
                setPaymentRequestSelected(record);
                setShowModalAttachment(true);
              }}
            />
          </Tooltip>
          <Popconfirm
            disabled={
              disabledActions(record)
            }
            placement="bottomRight"
            title={(
              <Text className="text-title-popconfirm">
                {record.estado === ConstantsPaymentRequestsStates.DRAFT ? t('text_approve_payment_request') : t('text_close_payment_request')}
              </Text>
            )}
            icon={<QuestionCircleOutlined style={styles.icon} />}
            onConfirm={() => {
              changeStatusPaymentRequest({
                id,
                status: record.estado === ConstantsPaymentRequestsStates.DRAFT ? ConstantsPaymentRequestsStates.PENDING.toLowerCase() : ConstantsPaymentRequestsStates.CLOSED.toLowerCase(),
              });
            }}
            okText={(
              <Text className="text-ok-button-popconfirm">
                {t('text_yes')}
              </Text>
            )}
            cancelText={(
              <Text className="text-cancel-btn-popconfirm">
                {t('text_no')}
              </Text>
            )}
          >
            <Tooltip title={record.estado === ConstantsPaymentRequestsStates.DRAFT ? t('text_approve_payment_request') : t('text_close_payment_request')}>
              <Button
                type="link"
                htmlType="button"
                disabled={
                  disabledActions(record)
                }
                icon={<CheckCircleOutlined style={styles.icon} />}
                style={styles.buttonLink}
              />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            disabled={
              disabledActions(record)
            }
            placement="bottomRight"
            title={(
              <Text className="text-title-popconfirm">
                {t('text_cancel_payment_request')}
              </Text>
            )}
            icon={<QuestionCircleOutlined />}
            onConfirm={() => {
              changeStatusPaymentRequest({
                id,
                status: ConstantsPaymentRequestsStates.CANCEL.toLowerCase(),
              });
            }}
            okText={(
              <Text className="text-ok-button-popconfirm">
                {t('text_yes')}
              </Text>
            )}
            cancelText={(
              <Text className="text-cancel-btn-popconfirm">
                {t('text_no')}
              </Text>
            )}
          >
            <Tooltip title={t('text_cancel_payment_request')}>
              <Button
                className="icon-disabled"
                type="link"
                htmlType="button"
                disabled={
              disabledActions(record)
                }
                icon={<CloseCircleOutlined />}
                style={styles.buttonLink}
              />
            </Tooltip>
          </Popconfirm>

        </div>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          {showModalNewPaymentRequest ? (
            <NewPaymentRequestModal
              handleOk={() => {
                setShowModalNewPaymentRequest(false);
              }}
              handleCancel={() => {
                setShowModalNewPaymentRequest(false);
              }}
              visible={showModalNewPaymentRequest}
            />
          ) : (
            ''
          )}
          {showModalAttachment ? (
            <AttachmentModal
              handleOk={() => {
                setShowModalAttachment(false);
              }}
              handleCancel={() => {
                setShowModalAttachment(false);
              }}
              paymentRequest={paymentRequestSelected}
            />
          ) : (
            ''
          )}
          <FilterPaymentRequest />
          <Row align="middle" justify="end">
            {getWritePermissionByTab(ConstantsRoutes.PAYMENT_REQUESTS) ? (
              <Col style={{ marginRight: 16 }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="small"
                  disabled={!getWritePermissionByTab(ConstantsRoutes.PAYMENT_REQUESTS)}
                  onClick={() => {
                    setShowModalNewPaymentRequest(true);
                  }}
                  className="btn btn-h-auto text text-w-bold px-3 py-2"
                >
                  {t('text_button_new_payment_request')}
                </Button>
              </Col>
            ) : <div />}
            <Col style={{ marginRight: 16 }}>
              <Button
                onClick={clearAllFilters}
                size="small"
                disabled={paymentRequests.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_clear_filters')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                size="small"
                disabled={paymentRequests.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  const wb = XLSX.utils.book_new();
                  const ws = XLSX.utils.json_to_sheet([]);
                  XLSX.utils.sheet_add_aoa(ws, [getExcelHeaders()]);
                  XLSX.utils.sheet_add_json(ws, getExcelData(), {
                    origin: 'A2',
                    skipHeader: true
                  });
                  XLSX.utils.book_append_sheet(wb, ws, 'solicitudesPago');
                  XLSX.writeFile(wb, 'solicitudesPago.xlsx');
                }}
              >
                {t('text_button_export')}
              </Button>
            </Col>
          </Row>
          <Table
            rowKey="id"
            style={{ width: '100%' }}
            pagination={{ total: paymentRequests.length, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'] }}
            size="small"
            loading={isFetchingGetPaymentRequests}
            columns={columns}
            dataSource={paymentRequests}
            onChange={handleChange}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) => (
                expanded ? (
                  <UpCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className="icon-payment-request"
                  />
                ) : (
                  <RightCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className="icon-payment-request"
                  />
                )
              ),
              expandedRowRender: (record) => (
                <Attachments paymentRequest={record} />
              ),
              rowExpandable: () => true,
            }}
            footer={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearAllFilters}
                    size="small"
                    disabled={paymentRequests.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + paymentRequests.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
}
