import {
  call, takeEvery, put,
} from 'redux-saga/effects';
import qs from 'qs';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import AxiosInstance from './axiosConfig';
import ConstantsPay from '../store/reducers/pay/payConstants';
import { actionGetDataPaymentResult } from '../store/reducers/pay/payActions';

function getDataPaymentApi(values) {
  return AxiosInstance.post(
    '/payment/procesado',
    qs.stringify({
      token: values.token,
      identificador: values.identification,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetDataPayment(values) {
  try {
    const { status, data } = yield call(getDataPaymentApi, values);
    if (status === 200) {
      const result = {
        mensaje: data.mensaje,
        code: data.code === 0,
        url: data.url,
      };
      yield put(actionGetDataPaymentResult(result));
    } else {
      yield put(actionGetDataPaymentResult(null));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetDataPaymentResult(null));
  }

  yield put(actionGetDataPaymentResult(null));
  yield put(actionLoading(false));
}

export default function* paySagas() {
  yield takeEvery(ConstantsPay.GET_DATA_PAYMENT, generateGetDataPayment);
}
