import Colors from '../../../../assets/colors/Colors';

export default {
  input: {
    borderRadius: 4,
    borderWidth: 1,
    fontSize: 14,
    paddingTop: 6,
    width: '100%',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
    marginLeft: 8,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: '100%',
  },
  textTitle: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  rowFilter: {
    width: '100%',
    padding: 16,
    backgroundColor: Colors.BACKGROUND_COLOR_2,
  },
  textTitleDescription: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
  icon: {
    color: Colors.ICON_COLOR,
  },
  select: {
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
};
