import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from './api';
import i18n from '../assets/language/i18n';

export const invitationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFiltersInvitationsApi: build.query({
      query: () => ({ method: 'POST', url: '/paybylink/getFiltros', data: new FormData() }),
      transformResponse: (response) => ({
        hotels: response?.hoteles_asociados?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        payStates: response?.estados?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        currencies: response?.monedas?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        chains: response?.cadena_hotelera?.sort(
          (a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language),
        ),
      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_filters'),
      }),
    }),
    getInvitationsApi: build.query({
      query: (values) => {
        const dataToSend = {};
        if (values.hotel?.length !== 0) {
          dataToSend.codigo_hotel = values.hotel;
        }
        if (values.idTransaction?.length !== 0) {
          dataToSend.localizador_reservas = values.idTransaction;
        }
        if (values.statePay?.length !== 0) {
          dataToSend.estado = values.statePay;
        }
        if (values.currency?.length !== 0) {
          dataToSend.moneda = values.currency;
        }
        if (values.dateInitSend?.length !== 0) {
          dataToSend.fecha_inicio = values.dateInitSend;
        }
        if (values.dateEndSend?.length !== 0) {
          dataToSend.fecha_final = values.dateEndSend;
        }
        if (values.dateInitTransaction?.length !== 0) {
          dataToSend.fecha_transaccion_inicio = values.dateInitTransaction;
        }
        if (values.dateEndTransaction?.length !== 0) {
          dataToSend.fecha_transaccion_final = values.dateEndTransaction;
        }
        if (values.idReservation?.length !== 0) {
          dataToSend.id_reserva = values.idReservation;
        }
        if (values.internalReference?.length !== 0) {
          dataToSend.referencia_interna = values.internalReference;
        }
        if (values.dateInitSendStart?.length !== 0) {
          dataToSend.fecha_inicio_servicio = values.dateInitSendStart;
        }
        if (values.dateInitSendEnd?.length !== 0) {
          dataToSend.fecha_inicio_servicio_final = values.dateInitSendEnd;
        }

        if (values.dateEndSendStart?.length !== 0) {
          dataToSend.fecha_fin_servicio = values.dateEndSendStart;
        }
        if (values.dateEndSendEnd?.length !== 0) {
          dataToSend.fecha_fin_servicio_final = values.dateEndSendEnd;
        }
        if (values.chain?.length !== 0) {
          dataToSend.cadena_hotelera = values.chain;
        }
        return { method: 'POST', url: '/paybylink/getPaybyLink', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => (response.enlace_pago.map((invitation) => (
        {
          ...invitation,
          id_linktopay: Number(invitation.id_linktopay),
          id_usuario: Number(invitation.id_usuario),
          id_transaccion: invitation.id_transaccion === null ? '' : invitation.id_transaccion,
          localizador: invitation.localizador === null ? '' : invitation.localizador,
          estado: invitation.estado.toUpperCase(),
        }
      ))),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_invitations'),
      }),
    }),
    sendInvitationApi: build.mutation({
      query: (id_linktopay) => ({ method: 'POST', url: '/paybylink/reenviarEnlace', data: qs.stringify({ id_linktopay }) }),
      transformResponse: () => ({ message: i18n.t('success_send_invitation') }),
      transformErrorResponse: () => ({
        message: i18n.t('error_send_invitation'),
      }),
    }),
    activateInvitationApi: build.mutation({
      query: (id_linktopay) => ({ method: 'POST', url: '/paybylink/reActivarEnlace', data: qs.stringify({ id_linktopay }) }),
      transformResponse: () => ({ message: i18n.t('success_activate_invitation') }),
      transformErrorResponse: () => ({
        message: i18n.t('error_activate_invitation'),
      }),
    }),
    disableInvitationApi: build.mutation({
      query: (id_linktopay) => ({ method: 'POST', url: '/paybylink/cancelar', data: qs.stringify({ id_linktopay }) }),
      transformResponse: () => ({message: i18n.t('success_disable_invitation') }),
      transformErrorResponse: () => ({
        message: i18n.t('error_activate_invitation'),
      }),
    }),
    createInvitationApi: build.mutation({
      query: (values) => {
        const dataToSend = {
          nombre: values.nameLastname,
          email: values.email,
          moneda: values.currency,
          concepto: values.concept,
          idioma: values.language,
          importe: values.amount,
          codigo_hotel: values.hotelCode,
          id_reserva: values.idReserva,
          localizador: values.localizator
        };

        if (values.dateInitSend) {
          dataToSend.fecha_inicio_servicio = values.dateInitSend;
          dataToSend.fecha_fin_servicio = values.dateEndSend;
        }
        return ({ method: 'POST', url: '/paybylink/nuevoPayByLink', data: qs.stringify(dataToSend) });
      },
      transformResponse: (response) => ({ ...response, message: i18n.t('success_create_invitation')}),
      transformErrorResponse: () => ({
        message: i18n.t('error_create_invitation'),
      }),
    }),

  }),
});

export const {
  useGetFiltersInvitationsApiQuery,
  useGetInvitationsApiQuery,
  useLazyGetInvitationsApiQuery,
  useSendInvitationApiMutation,
  useActivateInvitationApiMutation,
  useDisableInvitationApiMutation,
  useCreateInvitationApiMutation,
} = invitationsApi;

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState: {
    appliedFilters: null,
  },
  reducers: {
    setAppliedFilters(state, action) {
      state.appliedFilters = action.payload;
    },
  },
});

export const { setAppliedFilters } = invitationsSlice.actions;
export default invitationsSlice.reducer;
