import {
  call, takeEvery, put,
} from 'redux-saga/effects';
import qs from 'qs';
import i18n from '../assets/language/i18n';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import {request} from './axiosConfig';
import ConstantsPreConciliation from '../store/reducers/preConciliation/preConciliationConstants';
import {
  actionApplyRecalificationReservationsResult,
  actionChangeStatusReservationsResult,
  actionDeleteReclasificationReservationsResult,
  actionGetCommentPreConciliationResult,
  actionGetFiltersPreConciliationResult,
  actionGetPreConciliationReservationsResult,
  actionGetResumePreConciliationResult,
  actionSendCommentReservationsResult
} from '../store/reducers/preConciliation/preConciliationActions';

function getFiltersApi() {
  return request.post('/arreglo/getFiltros', new FormData())
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetFilters() {
  const filters = {
    hotels: [],
    channels: [],
    payStates: [],
    payTypes: [],
    payActions: [],
    reservationStates: [],
    clients: [],
    chains: [],
    currencies: [],
    concepts: [],
    crs: [],
  };
  try {
    const { status, data } = yield call(getFiltersApi);
    if (status === 200) {
      filters.hotels = data.hoteles?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.channels = data.canales?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.chains = data.cadena_hotelera?.sort(
        (a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language),
      );
      filters.payForms = data.codigo_ota?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.reservationStates = data.estado_reserva?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.payStates = data.estado?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.concepts = data.tipo?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.validations = data.validacion?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.clients = data.cliente?.sort(
        (a, b) => a?.cliente.localeCompare(b?.cliente, i18n.language),
      );
      filters.crs = data.crs?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.currencies = data.moneda?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );

      yield put(actionGetFiltersPreConciliationResult(true, null, filters));
    } else {
      yield put(actionGetFiltersPreConciliationResult(false, i18n.t('error_get_filters'), filters));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetFiltersPreConciliationResult(false, i18n.t('error_get_filters'), filters));
  }

  yield put(actionLoading(false));
}

function getReservationsApi(values) {
  const parameters = {};
  if (values.idTransaction?.length !== 0) {
    parameters.transaccion = values.idTransaction;
  }
  if (values.numberLocator?.length !== 0) {
    parameters.reserva = values.numberLocator;
  }
  if (values.stateReservation?.length !== 0) {
    parameters.estado_reserva = values.stateReservation;
  }
  if (values.payState?.length !== 0) {
    parameters.estado = values.payState;
  }
  if (values.concept?.length !== 0) {
    parameters.tipo = values.concept;
  }
  if (values.channel?.length !== 0) {
    parameters.canal = values.channel;
  }
  if (values.validations?.length !== 0) {
    parameters.validacion = values.validations;
  }
  if (values.chain?.length !== 0) {
    parameters.cadena_hotelera = values.chain;
  }
  if (values.nameHotel?.length !== 0) {
    parameters.codigo_hotel = values.nameHotel;
  }
  if (values.dateCreateStart?.length !== 0) {
    parameters.fecha_creacion = values.dateCreateStart;
  }
  if (values.dateCreateEnd?.length !== 0) {
    parameters.fecha_creacion_final = values.dateCreateEnd;
  }
  if (values.dateCheckinStart?.length !== 0) {
    parameters.fecha_checkin = values.dateCheckinStart;
  }
  if (values.dateCheckinEnd?.length !== 0) {
    parameters.fecha_checkin_final = values.dateCheckinEnd;
  }
  if (values.otaCode?.length !== 0) {
    parameters.codigo_ota = values.otaCode;
  }
  if (values.user?.length !== 0) {
    parameters.usuario = values.user;
  }
  if (values.client?.length !== 0) {
    parameters.cliente = values.client;
  }
  if (values.crs?.length !== 0) {
    parameters.crs = values.crs;
  }
  if (values.currency?.length !== 0) {
    parameters.moneda = values.currency;
  }
  if (values.externalNumberLocator?.length !== 0) {
    parameters.localizador_agencia = values.externalNumberLocator;
  }
  return request.post(
    '/arreglo/getLineasArreglo',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetReservations(values) {
  try {
    const { status, data } = yield call(getReservationsApi, values);
    if (status === 200) {
      const reservations = data.arreglo?.map((reservation) => (
        {
          ...reservation,
          fecha_actualizacion: reservation.fecha_actualizacion === null ? '' : new Date(reservation.fecha_actualizacion),
          fecha_actualizacion_string: reservation.fecha_actualizacion,
          fecha_checkin: reservation.fecha_checkin === null ? '' : new Date(reservation.fecha_checkin),
          fecha_checkin_string: reservation.fecha_checkin,
          fecha_checkout: reservation.fecha_checkout === null ? '' : new Date(reservation.fecha_checkout),
          fecha_checkout_string: reservation.fecha_checkout,
          fecha_creacion: reservation.fecha_creacion === null ? '' : new Date(reservation.fecha_creacion),
          fecha_creacion_string: reservation.fecha_creacion,
          noches: Number(reservation.noches),
          habitaciones: Number(reservation.habitaciones),
          canal: reservation.canal === null ? '' : reservation.canal,
        }
      ));
      yield put(actionGetPreConciliationReservationsResult(true, null, reservations));
    } else {
      yield put(actionGetPreConciliationReservationsResult(false, i18n.t('error_get_reservations'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetPreConciliationReservationsResult(false, i18n.t('error_get_reservations'), []));
  }

  yield put(actionLoading(false));
}

function applyRecalificationReservationsApi(values) {
  return request.post(
    '/arreglo/setReclasificacionOTA',
    JSON.stringify(values.reservations)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateApplyRecalificationReservations(values) {
  try {
    const { status, data } = yield call(applyRecalificationReservationsApi, values);
    if (status === 200 && data.code === '0') {
      yield put(actionApplyRecalificationReservationsResult(true, i18n.t('success_recalification_reservations')));
    } else {
      yield put(actionApplyRecalificationReservationsResult(false, data?.msg ?? i18n.t('success_recalification_reservations')));
    }
  } catch (error) {
    console.log(error);
    yield put(actionApplyRecalificationReservationsResult(false, i18n.t('error_recalification_reservations')));
  }

  yield put(actionLoading(false));
}

function changeStatusReservationsApi(values) {
  return request.post(
    '/arreglo/setValidacion',
    JSON.stringify(values.reservations)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateChangeStatusReservationsApi(values) {
  try {
    const { status, data } = yield call(changeStatusReservationsApi, values);
    if (status === 200 && data.code === '0') {
      yield put(actionChangeStatusReservationsResult(true, i18n.t('success_change_status_reservations')));
    } else {
      yield put(actionChangeStatusReservationsResult(false, data?.msg ?? i18n.t('error_change_status_reservations')));
    }
  } catch (error) {
    console.log(error);
    yield put(actionChangeStatusReservationsResult(false, i18n.t('error_change_status_reservations')));
  }

  yield put(actionLoading(false));
}

function getPreConciliationResumeResultApi(values) {
  const parameters = {};
  parameters.value = values.dateString;
  return request.post(
    '/arreglo/getInformeConciliacionByMes',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetPreConciliationResumeResultApi(values) {
  try {
    const { status, data } = yield call(getPreConciliationResumeResultApi, values);
    if (status === 200 && data.code === 0) {
      yield put(actionGetResumePreConciliationResult(true, null, data));
    } else {
      yield put(actionGetResumePreConciliationResult(false, data?.msg ?? i18n.t('error_get_resume'), null));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetResumePreConciliationResult(false, i18n.t('error_get_resume'), null));
  }

  yield put(actionLoading(false));
}

function getPreConciliationCommentResultApi(values) {
  const parameters = {};
  parameters.id_arreglo = values.id;
  return request.post(
    '/arreglo/getHistoricoArregloById',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetPreConciliationComment(values) {
  try {
    const { status, data } = yield call(getPreConciliationCommentResultApi, values);
    if (status === 200 && data.code === 0) {
      yield put(actionGetCommentPreConciliationResult(true, null, data.mensajes));
    } else {
      yield put(actionGetCommentPreConciliationResult(false, data?.msg ?? i18n.t('error_get_resume'), null));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetCommentPreConciliationResult(false, i18n.t('error_get_resume'), null));
  }

  yield put(actionLoading(false));
}

function sendPreConciliationCommentApi(values) {
  return request.post(
    '/arreglo/insertMensajeV2',
    JSON.stringify(values.reservations)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateSendPreConciliationComment(values) {
  try {
    const { status, data } = yield call(sendPreConciliationCommentApi, values);
    if (status === 200 && data.code === 0) {
      yield put(actionSendCommentReservationsResult(true, i18n.t('text_send_comment')));
    } else {
      yield put(actionSendCommentReservationsResult(false, data?.msg ?? i18n.t('error_send_comment'), null));
    }
  } catch (error) {
    console.log(error);
    yield put(actionSendCommentReservationsResult(false, i18n.t('error_send_comment'), null));
  }

  yield put(actionLoading(false));
}

function sendDeleteReclasificationReservationsApi(values) {
  return request.post(
    '/arreglo/borrarReclasificacionOta',
    JSON.stringify(values.reservations)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateDeleteReclasificationReservations(values) {
  try {
    const { status, data } = yield call(sendDeleteReclasificationReservationsApi, values);
    if (status === 200 && data.code === 0) {
      yield put(actionDeleteReclasificationReservationsResult(true, i18n.t('text_delete_recl_ota')));
    } else {
      yield put(actionDeleteReclasificationReservationsResult(false, data?.msg ?? i18n.t('error_delete_recl_ota'), null));
    }
  } catch (error) {
    console.log(error);
    yield put(actionDeleteReclasificationReservationsResult(false, i18n.t('error_delete_recl_ota'), null));
  }

  yield put(actionLoading(false));
}

export default function* preConciliationSagas() {
  yield takeEvery(ConstantsPreConciliation.GET_FILTERS_PRE_CONCILIATION, generateGetFilters);
  yield takeEvery(
    ConstantsPreConciliation.GET_PRE_CONCILIATION_RESERVATIONS,
    generateGetReservations
  );
  yield takeEvery(
    ConstantsPreConciliation.APPLY_RECALIFICATION_RESERVATIONS,
    generateApplyRecalificationReservations
  );
  yield takeEvery(
    ConstantsPreConciliation.CHANGE_STATUS_RESERVATIONS,
    generateChangeStatusReservationsApi
  );
  yield takeEvery(
    ConstantsPreConciliation.GET_RESUME_PRE_CONCILIATION,
    generateGetPreConciliationResumeResultApi
  );
  yield takeEvery(
    ConstantsPreConciliation.GET_COMMENT_PRE_CONCILIATION,
    generateGetPreConciliationComment
  );
  yield takeEvery(
    ConstantsPreConciliation.SEND_COMMENT_RESERVATIONS,
    generateSendPreConciliationComment
  );
  yield takeEvery(
    ConstantsPreConciliation.DELETE_RECALIFICATION_RESERVATIONS,
    generateDeleteReclasificationReservations
  );
}
