import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import 'antd/dist/antd.less';
import {
  CloseCircleOutlined,
  EuroCircleOutlined,
  FieldBinaryOutlined,
  FileExcelOutlined,
  MailOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
  UpCircleOutlined,
  RightCircleOutlined,
  FilePdfOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import moment from 'moment';
import Colors from '../../../assets/colors/Colors';
import i18n from '../../../assets/language/i18n';
import ConstantsDate from '../../../constants/ConstantsDate';
import FilterTpvTransactions from './FilterTpvTransactions';
import ConstantsTransactionStatus from '../../../constants/ConstantsTransactionStatus';
import {
  getWritePermissionByTab,
  getDevolutionPermission
} from '../../../utils/Utils';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';
import ConstantsLanguages from '../../../constants/ConstantsLanguages';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';

import {
  useCreateInvitationApiMutation
} from '../../../services/invitations';

import styles from './styles/stylesIndex';

import {
  actionGetDevolutionsByTransaction
} from '../../../store/reducers/tpvTransactions/tpvTransactionsActions';

import RefundTable from './RefundTable';
import { useLazyDownloadReceiptRequestPdfQuery } from '../../../services/tpvTransactionRequests';

import {addFilterItemToArray } from '../../../utils/helperFunctions';

const { Text } = Typography;
const { Option } = Select;

const CHARGE_KEY = 'Charge';
const showExpandableRow = (record) => record.action === CHARGE_KEY && (record.tipo_operacion === 'web' || record.tipo_operacion === 'linktopay') && (getDevolutionPermission())?.lectura;

export default function TpvTransactions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applyFilters = useSelector((state) => state.reducerFiltersTpvTransactions);
  const charges = useSelector((state) => state.reducerTpvTransactions);
  const loading = useSelector((state) => state.reducerLoading);

  const [visibleModal, setVisible] = useState(false);
  const [nameLastname, setNameLastname] = useState('');
  const [emailPay, setEmailPay] = useState('');
  const [serviceAmount, setServiceAmount] = useState(0);
  const [currency, setCurrency] = useState('');
  const [language, setLanguage] = useState(ConstantsLanguages.ES);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [reservation, setReservation] = useState(null);

  console.log(charges);

  const [
    downloadPdfFile,
    {
      isFetching: isDownloadingPdfFile,
      error: errorDownloadingPdfFile
    }
  ] = useLazyDownloadReceiptRequestPdfQuery();

  useEffect(() => {
    if (errorDownloadingPdfFile) {
      message.error(errorDownloadingPdfFile.message);
    }
  }, [errorDownloadingPdfFile]);

  const jwtData = useSelector(
    (state) => state.reducerJwtData
  );

  const [
    createInvitation,
    {
      data: createInvitationResult,
      isLoading: isLoadingCreateInvitation, // TODO annadir spinner
      error: errorCreateInvitation
    }
  ] = useCreateInvitationApiMutation();

  const isCubatourProfile = jwtData?.userInfo?.permisos?.tipo_agente === 'ctt';

  if (errorCreateInvitation) {
    message.error(errorCreateInvitation.message);
  }
  if (createInvitationResult?.message) {
    message.success(createInvitationResult.message);
  }

  useEffect(() => {
    if (charges.msg !== null && charges.result === false) {
      message.error(charges.msg);
    }
  });

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo(null);
  };

  const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => {
      let hintSearch;
      if (dataIndex === 'reserva' || dataIndex === 'localizador_reserva') {
        hintSearch = t('text_booking_locator');
      }
      if (dataIndex === 'reservation_id') {
        hintSearch = t('hint_number_locator');
      } else {
        hintSearch = t('text_transaction');
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={hintSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={styles.input}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={styles.buttonPrimary}
            >
              {t('text_button_filter')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={styles.buttonDefault}>
              {t('text_button_clear')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? Colors.PRIMARY_COLOR : Colors.ICON_COLOR }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text, record) => {
      if (dataIndex === 'reserva') {
        const recordStatus = record.action;
        let styleHigh;
        let styleNormal;
        if (recordStatus === ConstantsTransactionStatus.Charge) {
          styleHigh = styles.textValueHighGreenColumn;
          styleNormal = styles.textValueGreenColumn;
        } else if (recordStatus === ConstantsTransactionStatus.Error) {
          styleHigh = styles.textValueHighRedColumn;
          styleNormal = styles.textValueRedColumn;
        } else if (recordStatus === ConstantsTransactionStatus.Refund) {
          styleHigh = styles.textValueHighOrangeColumn;
          styleNormal = styles.textValueOrangeColumn;
        } else {
          styleHigh = styles.textValueHighYellowColumn;
          styleNormal = styles.textValueYellowColumn;
        }
        if (searchedColumn === dataIndex) {
          return (
            <Highlighter
              highlightStyle={styleHigh}
              unhighlightStyle={styleNormal}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          );
        } return (
          <Text style={styleNormal}>
            {text}
          </Text>
        );
      }
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        );
      } return (
        <Text className="text">
          {text}
        </Text>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleOk = () => {
    setVisible(false);
    createInvitation({
      nameLastname,
      localizator: reservation.reservation_id,
      email: emailPay,
      currency,
      concept: 'pago reserva de referencia',
      language,
      amount: serviceAmount,
      hotelCode: reservation.hotel,
      idReserva: reservation.reservation_id,
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getDevolutions = (idTransaction) => {
    dispatch(actionLoading(true));
    dispatch(actionGetDevolutionsByTransaction(idTransaction));
  };

  const filtersSelected = filteredInfo || {};

  const headers = [
    [t('text_action'),
      t('text_transaction'),
      t('text_operation_type'),
      t('text_date'),
      t('text_currency'),
      t('text_pay_total'),
      t('text_booking_locator'),
      isCubatourProfile ? t('text_office') : t('text_hotel'),
      t('text_card_number'),
      t('text_card_country'),
      t('text_card_brand'),
      t('text_issuer_bank'),
      t('text_external_booking_locator'),
      t('text_terminal'),
      t('text_customer_country'),
      t('text_error_code'),
    ]
  ];

  const csvData = [];

  charges.forEach((i) => {
    csvData.push({
      action: i.action,
      transaccion: i.transaccion,
      tipo_transacción: applyFilters?.operationTypes?.find((item) => item.value === i.tipo_operacion)?.label,
      fecha_transaccion_string: i.fecha_transaccion_string,
      currency: i.moneda,
      amount: Number(i.amount) ?? Number(0.00),
      reserva: i.reserva,
      nombre_hotel: i.nombre_hotel,
      cardNumber: i.tarjeta,
      cardCountry: i.cardCountry,
      cardBrand: i.cardBrand,
      issuerBank: i.issuerBank,
      localizador_reserva: i.localizador_reserva,
      terminal: i.terminal,
      costumerCountry: i.costumerCountry,
      error_code: i.error_code,
    });
  });

  const actionsOptions = [];
  const hotelFilters = [];
  const currencyFilters = [];
  const operationTypesFilter = [];
  const userFilter = [];
  const cardBrandFilter = [];
  const cardCountryFilter = [];
  const clientCountryFilter = [];
  const bankFilter = [];

  charges.forEach((charge) => {
    if (charge.action !== null && charge.action.length > 0) {
      if (!actionsOptions.some((action) => action.value === charge.action)) {
        actionsOptions.push({
          text: applyFilters.payStates?.filter((i) => i.estado === charge?.action)[0]?.label,
          value: charge?.action,
        });
      }
    }

    addFilterItemToArray(hotelFilters, charge.codigo_hotel, charge.nombre_hotel);
    addFilterItemToArray(userFilter, charge.nombre_usuario, charge.nombre_usuario);
    addFilterItemToArray(currencyFilters, charge.moneda, applyFilters.currencies?.filter((i) => i.currency === charge?.moneda)[0]?.label);
    addFilterItemToArray(operationTypesFilter, charge.tipo_operacion, applyFilters?.operationTypes?.find(
      (item) => item.value === charge.tipo_operacion
    )?.label);

    addFilterItemToArray(cardBrandFilter, charge.cardBrand);
    addFilterItemToArray(cardCountryFilter, charge.cardCountry);
    addFilterItemToArray(clientCountryFilter, charge.costumerCountry);
    addFilterItemToArray(bankFilter, charge.issuerBank);
  });

  userFilter.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  hotelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  actionsOptions.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  operationTypesFilter.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  cardBrandFilter.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  cardCountryFilter.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  clientCountryFilter.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  bankFilter.sort((a, b) => a.text.localeCompare(b.text, i18n.language));

  const hotelsOptions = [];
  applyFilters.hotels.forEach((i) => {
    hotelsOptions.push(
      <Option value={i.value} key={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const currencyOptions = [];
  applyFilters.currencies.forEach((i) => {
    currencyOptions.push(
      <Option value={i.currency}>
        <Text style={styles.textOptionValue}>
          {i.currency}
        </Text>
      </Option>,
    );
  });

  const languageOptions = [];
  languageOptions.push(
    <Option value={ConstantsLanguages.ES}>
      <Text style={styles.textOptionValue}>
        {t('text_language_es')}
      </Text>
    </Option>,
  );
  languageOptions.push(
    <Option value={ConstantsLanguages.EN}>
      <Text style={styles.textOptionValue}>
        {t('text_language_en')}
      </Text>
    </Option>,
  );

  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_action')}
        </Text>),
      dataIndex: 'action_text',
      key: 'action_text',
      filteredValue: filtersSelected.action_text || null,
      filters: actionsOptions,
      onFilter: (value, record) => record.action?.indexOf(value) === 0,
      render: (text, record) => {
        const recordStatus = record.action;
        let styleNormal;
        if (recordStatus === ConstantsTransactionStatus.Charge) {
          styleNormal = styles.textValueGreenColumn;
        } else if (recordStatus === ConstantsTransactionStatus.Error) {
          styleNormal = styles.textValueRedColumn;
        } else if (recordStatus === ConstantsTransactionStatus.Refund) {
          styleNormal = styles.textValueOrangeColumn;
        } else {
          styleNormal = styles.textValueYellowColumn;
        }
        return (
          <Tooltip title={record.descripcion_error}>
            <Text
              className="text"
              style={styleNormal}
              onClick={() => {
                if (record.descripcion_error !== null) {
                  if (recordStatus === ConstantsTransactionStatus.Error) {
                    Modal.error({
                      title: t('text_observation'),
                      content: (
                        <Text className="text">
                          {record.descripcion_error}
                        </Text>
                      ),
                    });
                  } else {
                    Modal.info({
                      title: t('text_observation'),
                      content: (
                        <Text className="text">
                          {record.descripcion_error}
                        </Text>
                      ),
                    });
                  }
                }
              }}
            >
              {text}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Text className="text">
          {t('text_transaction')}
        </Text>),
      dataIndex: 'transaccion',
      key: 'transaccion',
      ...getColumnSearchProps('transaccion'),
      filteredValue: filtersSelected.transaccion || null,
    },
    {
      title: (
        <Text className="text">
          {t('text_operation_type')}
        </Text>),
      dataIndex: 'tipo_operacion',
      key: 'tipo_operacion',
      sorter: (a, b) => a.tipo_operacion?.localeCompare(b.tipo_operacion, i18n.language),
      filteredValue: filtersSelected.tipo_operacion || null,
      filters: operationTypesFilter,
      onFilter: (value, record) => record.tipo_operacion?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {applyFilters?.operationTypes?.find((item) => item.value === text)?.label}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_booking_locator')}
        </Text>),
      dataIndex: 'reserva',
      key: 'reserva',
      ...getColumnSearchProps('reserva'),
      filteredValue: filtersSelected.reserva || null,
    },
    {
      title: (
        <Text className="text">
          {t('text_external_booking_locator')}
        </Text>),
      dataIndex: 'localizador_reserva',
      key: 'localizador_reserva',
      ...getColumnSearchProps('localizador_reserva'),
      filteredValue: filtersSelected.localizador_reserva || null,
    },
    {
      title: (
        <Text className="text">
          {t('text_date')}
        </Text>),
      dataIndex: 'fecha_transaccion',
      key: 'fecha_transaccion',
      sorter: (a, b) => a.fecha_transaccion - b.fecha_transaccion,
      render: (text, record) => (
        <Text className="text">
          {text?.length === 0 || record?.fecha_transaccion_string === '0000-00-00' ? '' : format(text, ConstantsDate.DATE_HOUR_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_init_service_date')}
        </Text>),
      dataIndex: 'fecha_inicio_servicio',
      key: 'fecha_inicio_servicio',
      sorter: {
        compare: (a, b) => moment(a.fecha_inicio_servicio).format('YYYYMMDD').localeCompare(moment(b.fecha_inicio_servicio).format('YYYYMMDD'), i18n.language),
      },
      render: (text) => (
        <Text className="text">
          {
          text && text !== '0000-00-00' ? moment(text).format('DD/MM/YYYY') : ''
          }
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_end_service_date')}
        </Text>),
      dataIndex: 'fecha_fin_servicio',
      key: 'fecha_fin_servicio',
      sorter: {
        compare: (a, b) => moment(a.fecha_fin_servicio).format('YYYYMMDD').localeCompare(moment(b.fecha_fin_servicio).format('YYYYMMDD'), i18n.language),
      },
      render: (text) => (
        <Text className="text">
          {
          text && text !== '0000-00-00' ? moment(text).format('DD/MM/YYYY') : ''
          }
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_currency')}
        </Text>),
      dataIndex: 'moneda',
      key: 'moneda',
      filteredValue: filtersSelected.moneda || null,
      filters: currencyFilters,
      onFilter: (value, record) => record.moneda?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">

          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_pay_total')}
        </Text>),
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => Number(a.amount) - Number(b.amount),
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },

    {
      title: (
        <Text className="text">
          {t('hint_user')}
        </Text>),
      dataIndex: 'nombre_usuario',
      key: 'nombre_usuario',
      sorter: (a, b) => a.nombre_usuario?.localeCompare(b.nombre_usuario, i18n.language),
      filteredValue: filtersSelected.nombre_usuario || null,
      filters: userFilter,
      onFilter: (value, record) => record.nombre_usuario?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {isCubatourProfile ? t('text_office') : t('text_hotel')}
        </Text>),
      dataIndex: 'nombre_hotel',
      key: 'nombre_hotel',
      sorter: (a, b) => a.nombre_hotel?.localeCompare(b.nombre_hotel, i18n.language),
      filteredValue: filtersSelected.nombre_hotel || null,
      filters: hotelFilters,
      onFilter: (value, record) => record.codigo_hotel?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('hint_card_number')}
        </Text>),
      dataIndex: 'tarjeta',
      key: 'tarjeta',
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_card_brand')}
        </Text>),
      dataIndex: 'cardBrand',
      key: 'cardBrand',
      sorter: (a, b) => a.cardBrand?.localeCompare(b.cardBrand, i18n.language),
      filteredValue: filtersSelected.cardBrand || null,
      filters: cardBrandFilter,
      onFilter: (value, record) => record.cardBrand?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_card_country')}
        </Text>),
      dataIndex: 'cardCountry',
      key: 'cardCountry',
      sorter: (a, b) => a.cardCountry?.localeCompare(b.cardCountry, i18n.language),
      filteredValue: filtersSelected.cardCountry || null,
      filters: cardCountryFilter,
      onFilter: (value, record) => record.cardCountry?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_issuer_bank')}
        </Text>),
      dataIndex: 'issuerBank',
      key: 'issuerBank',
      sorter: (a, b) => a.issuerBank?.localeCompare(b.issuerBank, i18n.language),
      filteredValue: filtersSelected.issuerBank || null,
      filters: bankFilter,
      onFilter: (value, record) => record.issuerBank?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_client_country')}
        </Text>),
      dataIndex: 'costumerCountry',
      key: 'costumerCountry',
      sorter: (a, b) => a.costumerCountry?.localeCompare(b.costumerCountry, i18n.language),
      filteredValue: filtersSelected.costumerCountry || null,
      filters: clientCountryFilter,
      onFilter: (value, record) => record.costumerCountry?.indexOf(value) === 0,
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: '',
      fixed: 'right',
      width: 40,
      render: (record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: (
          <Row>
            {getWritePermissionByTab(ConstantsRoutes.TPV_TRANSACTIONS) && record.action !== 'Charge' && record.action !== 'Refund' ? (
              <Tooltip title={t('new_pay_by_link')}>
                <Button
                  type="link"
                  htmlType="button"
                  disabled={!getWritePermissionByTab(ConstantsRoutes.TPV_TRANSACTIONS) || record.action === 'Charge' || record.action === 'Refund'}
                  onClick={() => {
                    setNameLastname(record.nombre ?? '');
                    setEmailPay(record.email ?? '');
                    setLanguage(ConstantsLanguages.ES);
                    setServiceAmount(record.amount ?? '');
                    setCurrency(record.moneda ?? '');
                    setReservation({
                      usuario: record.nombre ?? '',
                      email: record.email ?? '',
                      hotel: record.codigo_hotel ?? '',
                      reservation_id: record.reserva ?? '',
                      pendiente_pago: record.amount ?? '',
                      currency: record.moneda ?? '',
                    });
                    setVisible(true);
                  }}
                  icon={<PlusOutlined style={styles.iconSave} />}
                  style={styles.buttonLink}
                />
              </Tooltip>
            ) : <div />}
            {record.action === 'Charge'
              ? (
                <Tooltip title={t('download_payment_receipt')}>
                  <Button
                    type="link"
                    htmlType="button"
                    icon={<FilePdfOutlined style={styles.icon} />}
                    style={styles.buttonLink}
                    loading={isDownloadingPdfFile}
                    onClick={() => {
                      downloadPdfFile({id: record.id_paycomet_transacciones, name: `${t('payment_receipt')}-${record?.localizador_reserva}.pdf`});
                    }}
                  />
                </Tooltip>
              )
              : ''}

          </Row>),
      }),
    },
  ];

  return (
    <Spin spinning={loading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          <Modal
            destroyOnClose
            title={(
              <Text style={styles.textTitlePopconfirm}>
                {t('new_pay_by_link')}
              </Text>
            )}
            open={visibleModal}
            footer={null}
            onCancel={handleCancel}
            closeIcon={<CloseCircleOutlined style={styles.icon} />}
          >
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
                size: 'middle',
              }}
            >
              <Form.Item
                name="nameLastname"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined style={styles.icon} />}
                  placeholder={t('hint_name_lastname')}
                  defaultValue={reservation?.usuario ?? ''}
                  onChange={(text) => {
                    setNameLastname(text.target.value);
                  }}
                  value={nameLastname}
                  style={styles.inputModal}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined style={styles.icon} />}
                  placeholder={t('hint_email')}
                  defaultValue={reservation?.email ?? ''}
                  onChange={(text) => {
                    setEmailPay(text.target.value);
                  }}
                  type="email"
                  value={emailPay}
                  style={styles.inputModal}
                />
              </Form.Item>
              <Form.Item
                name="hotelCode"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
              >
                <Select
                  disabled
                  defaultValue={reservation?.hotel}
                  style={styles.selectModal}
                  placeholder={t('hint_hotel_name')}
                  value={reservation?.hotel}
                >
                  {hotelsOptions}
                </Select>
              </Form.Item>
              <Form.Item
                name="confirmationReservationCode"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
              >
                <Input
                  disabled
                  prefix={<FieldBinaryOutlined style={styles.icon} />}
                  placeholder={t('hint_confirmation_reservation_code')}
                  defaultValue={reservation?.reservation_id}
                  value={reservation?.reservation_id}
                  style={styles.inputModal}
                />
              </Form.Item>
              <Form.Item
                name="language"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
              >
                <Select
                  style={styles.selectModal}
                  placeholder={t('text_language')}
                  optionFilterProp="children"
                  defaultValue={ConstantsLanguages.ES}
                  onChange={(value) => {
                    setLanguage(value);
                  }}
                  value={language}
                >
                  {languageOptions}
                </Select>
              </Form.Item>
              <Form.Item
                name="serviceAmount"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
                style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
              >
                <Input
                  prefix={<EuroCircleOutlined style={styles.icon} />}
                  placeholder={t('hint_amount_service')}
                  defaultValue={reservation?.pendiente_pago ?? '0'}
                  onChange={(text) => {
                    setServiceAmount(text.target.value);
                  }}
                  value={serviceAmount}
                  style={styles.inputModal}
                />
              </Form.Item>
              <Form.Item
                name="currency"
                rules={[
                  {
                    required: true,
                    message: t('error_empty_field'),
                  },
                ]}
                style={{ display: 'inline-block', width: 'calc(30% - 8px)', marginLeft: 8 }}
              >
                <Select
                  style={styles.select}
                  placeholder={t('text_currency')}
                  defaultValue={reservation?.currency ?? ''}
                  optionFilterProp="children"
                  onChange={(value) => {
                    setCurrency(value);
                  }}
                  value={currency}
                >
                  {currencyOptions}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={nameLastname.length === 0
                    || emailPay.length === 0
                    || serviceAmount.length === 0
                    || serviceAmount === '0'
                    || currency.length === 0}
                  onClick={handleOk}
                  style={styles.buttonPrimaryModal}
                >
                  {t('text_button_generate_link')}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <FilterTpvTransactions />
          <Row align="middle" justify="end">
            <Col style={{ marginRight: 16 }}>
              <Button
                onClick={clearAllFilters}
                size="small"
                disabled={charges.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_clear_filters')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                size="small"
                disabled={charges.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  const wb = XLSX.utils.book_new();
                  const ws = XLSX.utils.json_to_sheet([]);
                  XLSX.utils.sheet_add_aoa(ws, headers);
                  XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });
                  XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                  XLSX.writeFile(wb, 'cobros.xlsx');
                }}
              >
                {t('text_button_export')}
              </Button>
            </Col>
          </Row>
          <Table
            scroll={{ x: 2500 }}
            rowKey="id_paycomet_transacciones"
            style={{ width: '100%' }}
            pagination={{ total: charges.length, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'] }}
            size="small"
            loading={loading}
            columns={columns}
            dataSource={charges}
            onChange={handleChange}
            footer={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearAllFilters}
                    size="small"
                    disabled={charges.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon={<FileExcelOutlined />}
                    size="small"
                    disabled={charges.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                    onClick={() => {
                      const wb = XLSX.utils.book_new();
                      const ws = XLSX.utils.json_to_sheet([]);
                      XLSX.utils.sheet_add_aoa(ws, headers);
                      XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });
                      XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                      XLSX.writeFile(wb, 'cobros.xlsx');
                    }}
                  >
                    {t('text_button_export')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + charges.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
            expandable={{
              expandRowByClick: true,
              rowExpandable: (record) => showExpandableRow(record),
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!showExpandableRow(record)) {
                  return null;
                }
                return expanded ? (
                  <UpCircleOutlined onClick={(e) => onExpand(record, e)} style={styles.icon} />
                ) : (
                  <RightCircleOutlined
                    onClick={(e) => {
                      onExpand(record, e);
                    }}
                    style={styles.icon}
                  />
                );
              },
              expandedRowRender: (record) => (<RefundTable transaction={record} refreshDevolutionsHandler={() => getDevolutions(record.id_paycomet_transacciones)} />),
              onExpand: (expanded, record) => {
                if (!expanded) return;
                getDevolutions(record.id_paycomet_transacciones);
              },
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
}
